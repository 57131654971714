import { Container, FormInput, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import React from 'react'
import { GenerateReportRequest, ReportDetail } from 'src/models/api/GenerateReportRequestFormData'
import Style from '../../../styles/Reports.module.sass'

interface CommercialAndMarketAssumptionsProps {
  formData: GenerateReportRequest
  setFormData: React.Dispatch<React.SetStateAction<GenerateReportRequest>>
  error: Record<string, string>
}

const CommercialAndMarketAssumptions: React.FC<CommercialAndMarketAssumptionsProps> = ({
  formData,
  error,
  setFormData,
}) => {
  const handleInputChange = (value: string | number, key: keyof ReportDetail) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      report_detail: {
        ...prevFormData.report_detail,
        [key]: value,
      },
    }))
  }

  const getErrorMessage = (key: keyof ReportDetail) => {
    const optionalFields: Array<keyof ReportDetail> = ['nem_region']

    if (optionalFields.includes(key)) {
      return ''
    }

    return error[`report_detail.${key}`] || ''
  }

  return (
    <Container fluid>
      <div className={Style.tbl_container}>
        <Table headers={[{ label: 'Scenario Summary' }, { label: 'Assumption' }]}>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Commercial Operations Date</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData?.report_detail?.commercial_operation_date)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Economic Life</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.economic_life)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>NEM region</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <FormInput
                type='text'
                value={formData.report_detail.nem_region}
                onChange={(e) => handleInputChange(e, 'nem_region')}
                placeholder='Enter NEM region'
                error={getErrorMessage('nem_region')}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Contingency FCAS registered capacity</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;R1</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.contingency_fcas_registered_capacity.r1_max_registered_capacity)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;R6</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.contingency_fcas_registered_capacity.r6_max_registered_capacity)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;R60</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.contingency_fcas_registered_capacity.r60_max_registered_capacity)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;R5</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.contingency_fcas_registered_capacity.r5_max_registered_capacity)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;L1</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.contingency_fcas_registered_capacity.l1_max_registered_capacity)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;L6</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.contingency_fcas_registered_capacity.l6_max_registered_capacity)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;L60</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.contingency_fcas_registered_capacity.l60_max_registered_capacity)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;L5</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.contingency_fcas_registered_capacity.l5_max_registered_capacity)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Regulation FCAS registered capacity</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;Raise Reg</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.regulation_fcas_registered_capacity.raise_reg)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;Lower Reg</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.regulation_fcas_registered_capacity.lower_reg)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Load Marginal Loss Factor Start of Life</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{formData.report_detail.load_marginal_loss_factor_start_of_life}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Load Marginal Loss Factor End of Life</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{formData.report_detail.load_marginal_loss_factor_end_of_life}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Generation Marginal Loss Factor Start of Life</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{formData.report_detail.generation_marginal_loss_factor_start_of_life}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Generation Marginal Loss Factor End of Life</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{formData.report_detail.generation_marginal_loss_factor_end_of_life}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Percentage of perfect correction factors</p>
              <ul>
                <li>
                  <p>Wholesale energy</p>
                </li>
                <li>
                  <p>Regulation FCAS</p>
                </li>
                <li>
                  <p>Contingency FCAS</p>
                </li>
              </ul>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>&nbsp;&nbsp;</p>
              <ul className={Style.listNoFormat}>
                <li>
                  <p>{formData.report_detail.pop_wholesale_energy_generation}</p>
                </li>
                <li>
                  <p>{formData.report_detail.pop_reg_fcas}</p>
                </li>
                <li>
                  <p>{formData.report_detail.pop_contingency_fcas}</p>
                </li>
              </ul>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Capital Cost</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{formData.report_detail.capital_cost}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Average total O & M cost</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{formData.report_detail.average_total_o_and_m_cost}</p>
            </TableCell>
          </TableRow>
        </Table>
      </div>
    </Container>
  )
}

export default CommercialAndMarketAssumptions
