import config from '../config/config'
import { User } from '@auth0/auth0-react'

export const getUserRoles = (user: User | undefined): string[] => {
  return user?.[config.AUTH0_ROLES] || []
}

export const isRoleEmpty = (roleArray: string[]): boolean => {
  const roleAdmin = 'ESRO - Project Admin'

  if (roleArray?.includes(roleAdmin)) {
    return false
  } else {
    return true
  }
}
