import { FAILEDCOLOR, IN_PROGRESSCOLOR, SUCCESSCOLOR } from './colors'

const constant = {
  UPLOAD_IMAGE_SIZE_LIMIT: 5,
  FORECAST_FILE_TYPE: ['.csv'],
}

const STATUS_COLOR: { [key: string]: string } = {
  Successful: SUCCESSCOLOR,
  Failed: FAILEDCOLOR,
  InProgress: IN_PROGRESSCOLOR,
}

const FORECAST_FILE_STATUS: { [key: string]: string } = {
  IN_PROGRESS: 'In Progress',
  SUCCESSFUL: 'Successful',
  FAILED: 'Failed',
}

const FORECAST_TABLE_HEADERS = [
  'Date',
  'Time',
  'ENERGY',
  'RREG',
  'LREG',
  'R1',
  'L1',
  'R6',
  'L6',
  'R60',
  'L60',
  'R5m',
  'L5m',
]

const GENERATING_TABLE_HEADERS = ['Date', 'Time', 'DC Power']

const FORECAST_FILTER_DROPDOWN_ITEMS = [
  {
    id: 0,
    label: 'Forecast File Name',
  },
  {
    id: 1,
    label: 'Period',
  },
  {
    id: 2,
    label: 'Created Date',
  },
]

const DEFAULTPAGINATIONDISPLAYPAGES = 10

export {
  constant,
  STATUS_COLOR,
  FORECAST_FILE_STATUS,
  FORECAST_TABLE_HEADERS,
  FORECAST_FILTER_DROPDOWN_ITEMS,
  DEFAULTPAGINATIONDISPLAYPAGES,
  GENERATING_TABLE_HEADERS,
}
