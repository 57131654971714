import { Container, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import React from 'react'
import { ReportDetailResponeModal } from 'src/models/api/ReportDetailResponeModal'
import Style from '../../../styles/Reports.module.sass'

interface ViewCommercialAndMarketAssumptionsProps {
  formData: ReportDetailResponeModal
}

const ViewCommercialAndMarketAssemptions: React.FC<ViewCommercialAndMarketAssumptionsProps> = ({ formData }) => {
  return (
    <Container fluid>
      <div className={Style.tbl_container}>
        <Table headers={[{ label: 'Scenario Summary' }, { label: 'Assumption' }]}>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Commercial Operations Date</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              {formData.report_detail?.commercial_operation_date?.toString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Economic Life</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              {formData?.report_detail?.economic_life?.toString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>NEM region</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>{formData?.report_detail?.nem_region?.toString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Contingency FCAS registered capacity</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;R1</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.contingency_fcas_registered_capacity.r1_max_registered_capacity)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;R6</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.contingency_fcas_registered_capacity.r6_max_registered_capacity)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;R60</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.contingency_fcas_registered_capacity.r60_max_registered_capacity)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;R5</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.contingency_fcas_registered_capacity.r5_max_registered_capacity)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;L1</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.contingency_fcas_registered_capacity.l1_max_registered_capacity)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;L6</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.contingency_fcas_registered_capacity.l6_max_registered_capacity)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;L60</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.contingency_fcas_registered_capacity.l60_max_registered_capacity)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;L5</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.contingency_fcas_registered_capacity.l5_max_registered_capacity)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Regulation FCAS registered capacity</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;Raise Reg</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.regulation_fcas_registered_capacity.raise_reg)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;Lower Reg</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>{String(formData.report_detail.regulation_fcas_registered_capacity.lower_reg)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataReportPadding} style={{ width: '300px' }}>
              <p>Load Marginal Loss Factor Start of Life</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataReportPadding}>
              <p>{formData.report_detail.load_marginal_loss_factor_start_of_life}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataReportPadding} style={{ width: '300px' }}>
              <p>Load Marginal Loss Factor End of Life</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataReportPadding}>
              <p>{formData.report_detail.load_marginal_loss_factor_end_of_life}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataReportPadding} style={{ width: '300px' }}>
              <p>Generation Marginal Loss Factor Start of Life</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataReportPadding}>
              <p>{formData.report_detail.generation_marginal_loss_factor_start_of_life}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataReportPadding} style={{ width: '300px' }}>
              <p>Generation Marginal Loss Factor End of Life</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataReportPadding}>
              <p>{formData.report_detail.generation_marginal_loss_factor_end_of_life}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataReportPadding} style={{ width: '300px' }}>
              <p>Percentage of perfect correction factors</p>
              <ul>
                <li>
                  <p>Wholesale energy</p>
                </li>
                <li>
                  <p>Regulation FCAS</p>
                </li>
                <li>
                  <p>Contingency FCAS</p>
                </li>
              </ul>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <p>&nbsp;&nbsp;</p>
              <ul className={Style.listNoFormat}>
                <li>
                  <p>{formData.report_detail.pop_wholesale_energy_generation}</p>
                </li>
                <li>
                  <p>{formData.report_detail.pop_reg_fcas}</p>
                </li>
                <li>
                  <p>{formData.report_detail.pop_contingency_fcas}</p>
                </li>
              </ul>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataReportPadding} style={{ width: '300px' }}>
              <p>Capital Cost</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataReportPadding}>
              {formData?.report_detail.capital_cost.toString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataReportPadding} style={{ width: '300px' }}>
              <p>Average total O & M cost</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataReportPadding}>
              {formData?.report_detail?.average_total_o_and_m_cost?.toString()}
            </TableCell>
          </TableRow>
        </Table>
      </div>
    </Container>
  )
}

export default ViewCommercialAndMarketAssemptions
