export enum ExecutionTableHeaders {
  SCENARIOS = 'Scenarios',
  EXECUTION_DATE = 'Execution Date',
  EXECUTE_BY = 'Execute By',
  ASSOCIATED_FILE = 'Associated Forecast File',
  STATUS = 'Status',
  ACTION = 'Action',
}

export enum OverflowMenuItems {
  SEE_DETAILS = 'View Scenario Parameters',
  VIEW_IRR_NPV = 'View Detailed IRR/NPV Calculations',
  DOWNLOAD_SUMMARY = 'Download Summary File',
  DOWNLOAD_FORECAST = 'Download Detailed Output Files',
  DOWNLOAD_ERROR_LOG = 'Download Error Log',
}
export const ExecutionsDataMapping = [
  { label: 'Capex', key: 'capex_npv', dataKey: 'capex' },
  {
    label: 'LTSA',
    key: 'ltsa_npv',
    dataKey: 'o_and_m',
    yearKey: 'operating_year',
    valueKey: 'long_term_service_agreement_cost_percentage',
  },
  {
    label: 'Extended Warranties',
    key: 'warranty_npv',
    dataKey: 'o_and_m',
    yearKey: 'operating_year',
    valueKey: 'warranty_cost_percentage',
  },
  { label: 'LGC', key: 'lgc_npv', dataKey: 'lgc_data', yearKey: 'operating_year', valueKey: 'present_value' },
  { label: 'TUOS', key: 'tuos_npv', dataKey: 'o_and_m', yearKey: 'operating_year', valueKey: 'tuos_cost' },
  {
    label: 'NMS Contracts',
    key: 'nms_contracts_npv',
    dataKey: 'nms_contracts',
    yearKey: 'operating_year',
    valueKey: 'non_market_service',
  },
  {
    label: 'Cap Contracts',
    key: 'cap_contracts_npv',
    dataKey: 'cap_contracts',
    yearKey: 'operating_year',
    valueKey: 'cap_cost_at',
  },
  {
    label: 'Reg FCAS',
    key: 'regulation_fcas_npv',
    dataKey: 'regulation_fcas',
    yearKey: 'year',
    valueKey: 'present_value',
  },
  {
    label: 'Contingency FCAS',
    key: 'contingency_fcas_npv',
    dataKey: 'contingency_fcas',
    yearKey: 'year',
    valueKey: 'present_value',
  },
  {
    label: 'Wholesale Market',
    key: 'energy_market_npv',
    dataKey: 'energy_market',
    yearKey: 'year',
    valueKey: 'present_value',
  },
]

export const IRRNVPModalHeaders = [
  {
    label: 'Net Present Values',
  },
  {
    label: '',
  },
]
