import React from 'react'
import { Container, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import Style from '../../../styles/Reports.module.sass'
import { GenerateReportRequest, TechnicalAssumption } from 'src/models/api/GenerateReportRequestFormData'

interface TechnicalAssumptionsProps {
  formData: GenerateReportRequest
  setFormData: React.Dispatch<React.SetStateAction<GenerateReportRequest>>
  error: Record<string, string>
  projectType: number | undefined
}

const TechnicalAssumptions: React.FC<TechnicalAssumptionsProps> = ({ formData, projectType }) => {
  const technicalAssumptionList: TechnicalAssumption[] = formData?.report_detail?.technical_assumptions

  return (
    <Container fluid>
      <div className={Style.tbl_container}>
        <Table
          headers={[
            { label: 'Year' },
            { label: 'Energy Storage Retention' },
            { label: 'Round Trip Efficiency' },
            ...(projectType !== 1 ? [{ label: 'RE generation (MWh)' }] : []),
          ]}
        >
          {technicalAssumptionList.map((item: TechnicalAssumption) => (
            <TableRow key={item.operating_year}>
              <TableCell cellClass={Style.tableDataPadding}>
                <p>{item.operating_year}</p>
              </TableCell>
              <TableCell cellClass={Style.tableDataPadding}>
                <p>{item.energy_storage_retention}</p>
              </TableCell>
              <TableCell cellClass={Style.tableDataPadding}>
                <p>{item.round_trip_efficiency}</p>
              </TableCell>
              {projectType !== 1 && (
                <TableCell cellClass={Style.tableDataPadding}>
                  <p>{item.re_generation}</p>
                </TableCell>
              )}
            </TableRow>
          ))}
        </Table>
      </div>
    </Container>
  )
}

export default TechnicalAssumptions
