import { ScenarioFormData } from 'src/models/ScenarioFormData'
import * as Yup from 'yup'

type FormData = ScenarioFormData
interface ValidationContext {
  scenario_project_type: number
  coupled_type?: number
}
const currentYear = new Date().getFullYear()
const step1ValidationSchema = Yup.object().shape({
  project_life_years: Yup.string()
    .required('Project life (years) is required')
    .matches(/^\d*$/, 'Project life (years) must be a numeric value')
    .test('min', 'Project life should not be 0', (value) => {
      if (!value) return true
      return parseFloat(value) > 0
    })
    .test('integer', 'Project life (years) must be an integer', (value) => {
      if (!value) return true
      return Number.isInteger(parseFloat(value))
    }),

  forecast_data_id: Yup.string().required('Forecast file is required'),
  generating_profile_id: Yup.string().test(
    'conditional-validation',
    'Generation Profile is required',
    function (value) {
      const { scenario_project_type } = this.parent

      if (scenario_project_type === 1) {
        return true
      }
      return value ? true : this.createError()
    },
  ),
  year_of_deployment: Yup.string()
    .required('Year of deployment is required')
    .matches(/^\d{4}$/, 'Year of deployment must be a 4-digit year')
    .test('is-current-or-earlier-year', `Year of deployment must be ${currentYear} or later`, function (value) {
      return parseInt(value, 10) >= currentYear
    })
    .test(
      'is-less-than-first_year_of_commercial_operation',
      'Year of deployment must be less than the first year of commercial operation',
      function (value) {
        return parseInt(value, 10) <= parseInt(this.parent.first_year_of_commercial_operation, 10)
      },
    ),
  first_year_of_commercial_operation: Yup.string()
    .required('First year of commercial operation is required')
    .matches(/^\d{4}$/, 'First year of commercial operation must be a 4-digit year')
    .test(
      'is-current-or-future-year',
      `First year of commercial operation must be ${currentYear} or later`,
      function (value) {
        return parseInt(value, 10) >= currentYear
      },
    ),
  inverter_capacity: Yup.number()
    .integer('Inverter capacity operation must be a numeric value')
    .min(0, 'Must be at least 0')
    .required('Inverter capacity operation is required'),
  overload_charge_power: Yup.number().integer('Must be an integer').min(0, 'Must be at least 0'),
  overload_discharge_power: Yup.number().integer('Must be an integer').min(0, 'Must be at least 0'),

  solve_interval_hours: Yup.string()
    .required('Solve Interval Hours is required')
    .test('integer', 'Must be an integer', (value) => {
      if (value === undefined || value === null) return true
      return Number.isInteger(parseFloat(value))
    }),

  generation_mlf: Yup.string()
    .nullable()
    .notRequired()
    .test('max', 'must be at most 1', (value) => !value || parseFloat(value) <= 1)
    .test('min', 'must be at least 0', (value) => !value || parseFloat(value) >= 0)
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero', excludeEmptyString: true })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),

  rated_charge_power: Yup.number().integer('Must be an integer').min(0, 'Must be at least 0'),
  max_energy_input_from_grid: Yup.string()
    .required('This field is required')
    .test('min', 'must be at least 0', (value) => !value || parseFloat(value) >= 0)
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero', excludeEmptyString: true })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  time_res: Yup.string().required('this field is required'),
  max_vre_inverter_capacity_out: Yup.string()
    .nullable()
    .test('conditional-validation', function (value) {
      const { hybrid_generation_type, coupled_type, scenario_project_type } = this.parent

      if (scenario_project_type === 1) {
        return true
      }

      const shouldValidateMaxVre =
        (hybrid_generation_type === '2' && coupled_type === '1') ||
        (hybrid_generation_type === '1' && coupled_type === '1')

      if (shouldValidateMaxVre) {
        if (!value) {
          return this.createError({ message: 'This field is required' })
        }
        if (parseFloat(value) <= 0) {
          return this.createError({ message: 'Value must be greater than 0' })
        }
      }

      return true
    }),
  max_energy_output_to_grid: Yup.string()
    .required('This field is required')
    .test('min', 'must be at least 0', (value) => !value || parseFloat(value) >= 0)
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero', excludeEmptyString: true })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  window_hours: Yup.string()
    .required('Window Hours is required')
    .test('integer', 'Must be an integer', (value) => {
      if (value === undefined || value === null) return true
      return Number.isInteger(parseFloat(value))
    })
    .test('greater-or-equal', 'Window Hours must be greater than or equal to Solve Interval Hours', function (value) {
      const { solve_interval_hours } = this.parent // Access other field values from the parent object

      if (
        value === undefined ||
        value === null ||
        solve_interval_hours === undefined ||
        solve_interval_hours === null
      ) {
        return true // Skip validation if any value is undefined or null
      }

      const windowHours = parseFloat(value)
      const solveIntervalHours = parseFloat(solve_interval_hours)

      return windowHours >= solveIntervalHours
    }),

  max_bess_inverter_capacity_out: Yup.string()
    .nullable()
    .test('conditional-validation', function (value) {
      const { hybrid_generation_type, coupled_type, scenario_project_type } = this.parent

      if (scenario_project_type === 1) {
        return true
      }

      const shouldValidateMaxInverter =
        (hybrid_generation_type === '2' && (coupled_type === '1' || coupled_type === '2')) ||
        (hybrid_generation_type === '1' && coupled_type === '1')

      if (shouldValidateMaxInverter) {
        if (!value) {
          return this.createError({ message: 'This field is required' })
        }
        if (parseFloat(value) <= 0) {
          return this.createError({ message: 'Must be greater than 0' })
        }
      }

      return true
    }),
  rated_discharge_power: Yup.number().integer('Must be an integer').min(0, 'Must be at least 0'),
  max_bess_inverter_capacity_in: Yup.string()
    .nullable()
    .test('conditional-validation', function (value) {
      const { hybrid_generation_type, coupled_type, scenario_project_type } = this.parent

      if (scenario_project_type === 1) {
        return true
      }
      const shouldValidateMaxInverterIn =
        (hybrid_generation_type === '2' && (coupled_type === '1' || coupled_type === '2')) ||
        (hybrid_generation_type === '1' && coupled_type === '1')

      if (shouldValidateMaxInverterIn) {
        if (!value) {
          return this.createError({ message: 'This field is required' })
        }
        if (parseFloat(value) <= 0) {
          return this.createError({ message: 'Must be greater than 0' })
        }
      }

      return true
    }),
})

const lossFactorSchema = Yup.object().shape({
  operating_year: Yup.number().required(),
  generation_mlf: Yup.string()
    .nullable()
    .notRequired()
    .test('max', 'Marginal Loss Factor must be between 0 and 1.5', (value) => !value || parseFloat(value) <= 1.5)
    .test('min', 'Marginal Loss Factor must be between 0 and 1.5', (value) => !value || parseFloat(value) >= 0)
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero', excludeEmptyString: true })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  load_mlf: Yup.string()
    .nullable()
    .notRequired()
    .test('min', 'Marginal Loss Factor must be between 0 and 1.5', (value) => !value || parseFloat(value) >= 0)
    .test('max', 'Marginal Loss Factor must be between 0 and 1.5', (value) => !value || parseFloat(value) <= 1.5)
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero', excludeEmptyString: true })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),

  created_at: Yup.string(),
  updated_at: Yup.string(),
  created_by: Yup.string(),
  updated_by: Yup.string(),
})

const nmsSchema = Yup.object().shape({
  operating_year: Yup.number().required(),
  nms_valperhour: Yup.string()
    .required()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),

  created_at: Yup.string(),
  updated_at: Yup.string(),
  created_by: Yup.string(),
  updated_by: Yup.string(),
})

const step2ValidationSchema = Yup.object().shape({
  use_client_nominated_market_elasticity_factors: Yup.string(),
  market_elasticity_reg_lower: Yup.string()
    .required()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  r6_max_registered_capacity: Yup.string()
    .required('CFCAS Market (R6) is required')
    .test('min', 'Value must be between 0 and 100', (value) => parseFloat(value) >= 0)
    .test('max', 'Value must be between 0 and 100', (value) => parseFloat(value) <= 100)
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),

  r5_max_registered_capacity: Yup.string()
    .required('CFCAS Market (R5) is required')
    .test('min', 'Value must be between 0 and 100', (value) => parseFloat(value) >= 0)
    .test('max', 'Value must be between 0 and 100', (value) => parseFloat(value) <= 100)
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  r60_max_registered_capacity: Yup.string()
    .required('CFCAS Market (R60) is required')
    .test('min', 'Value must be between 0 and 100', (value) => parseFloat(value) >= 0)
    .test('max', 'Value must be between 0 and 100', (value) => parseFloat(value) <= 100)
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  r1_max_registered_capacity: Yup.string()
    .required('CFCAS Market (R1) is required')
    .test('min', 'Value must be between 0 and 100', (value) => parseFloat(value) >= 0)
    .test('max', 'Value must be between 0 and 100', (value) => parseFloat(value) <= 100)
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),

  l1_max_registered_capacity: Yup.string()
    .required('CFCAS Market (L1) is required')
    .test('min', 'Value must be between 0 and 100', (value) => parseFloat(value) >= 0)
    .test('max', 'Value must be between 0 and 100', (value) => parseFloat(value) <= 100)
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),

  l60_max_registered_capacity: Yup.string()
    .required('CFCAS Market (L60) is required')
    .test('min', 'Value must be between 0 and 100', (value) => parseFloat(value) >= 0)
    .test('max', 'Value must be between 0 and 100', (value) => parseFloat(value) <= 100)
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  l6_max_registered_capacity: Yup.string()
    .required('CFCAS Market (L6) is required')
    .test('min', 'Value must be between 0 and 100', (value) => parseFloat(value) >= 0)
    .test('max', 'Value must be between 0 and 100', (value) => parseFloat(value) <= 100)
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),

  l5_max_registered_capacity: Yup.string()
    .required('CFCAS Market (L5) is required')
    .test('min', 'Value must be between 0 and 100', (value) => parseFloat(value) >= 0)
    .test('max', 'Value must be between 0 and 100', (value) => parseFloat(value) <= 100)
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),

  market_elasticity_wholesale: Yup.string()
    .required()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),

  r1_market_elasticity: Yup.string()
    .required()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  r6_market_elasticity: Yup.string()
    .required()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  r5_market_elasticity: Yup.string()
    .required()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),

  r60_market_elasticity: Yup.string()
    .required()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  market_elasticity_reg_raise: Yup.string()
    .required()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  l60_market_elasticity: Yup.string()
    .required()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  l1_market_elasticity: Yup.string()
    .required()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  l5_market_elasticity: Yup.string()
    .required()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  l6_market_elasticity: Yup.string()
    .required()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  reg_raise_max_enablement: Yup.string()
    .required('Market (Reg raise) is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  reg_lower_max_enablement: Yup.string()
    .required('Market (Reg lower) is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  r1_max_enablement: Yup.string()
    .required('Market (R1) is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  r6_max_enablement: Yup.string()
    .required('Market (R6) is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  r60_max_enablement: Yup.string()
    .required('Market (R60) is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  r5_max_enablement: Yup.string()
    .required('Market (R5) is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  l1_max_enablement: Yup.string()
    .required('Market (L1) is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  l6_max_enablement: Yup.string()
    .required('Market (L6) is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  l60_max_enablement: Yup.string()
    .required('Market (L60) is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  l5_max_enablement: Yup.string()
    .required('Market (L5) is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  rreg_utilisation: Yup.string()
    .required('Raise Reg Utilisation is required')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number' })
    .test('positive-number', 'Must be greater than 0', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num > 0
    }),
  loss_factors: Yup.array().of(lossFactorSchema),
  inertia_utilisation: Yup.string()
    .required('inertia_utilisation is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  lreg_utilisation: Yup.string()
    .required('Lower Reg Utilisation is required')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number' })
    .test('positive-number', 'Must be greater than 0', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num > 0
    }),
})

const capContractsSchema = Yup.object().shape({
  year: Yup.number().required('Year is required'),
  q1_cap_volume: Yup.string()
    .required('Q1 Cap Volume is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  q1_cap_price: Yup.string()
    .required('Q1 Cap Price is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  q2_cap_volume: Yup.string()
    .required('Q2 Cap Volume is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  q2_cap_price: Yup.string()
    .required('Q2 Cap Price is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  q3_cap_volume: Yup.string()
    .required('Q3 Cap Volume is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  q3_cap_price: Yup.string()
    .required('Q3 Cap Price is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  q4_cap_volume: Yup.string()
    .required('Q4 Cap Volume is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  q4_cap_price: Yup.string()
    .required('Q4 Cap Price is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  q1_cap_strike_price: Yup.string()
    .required('Q1 Cap Strike Price is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  q2_cap_strike_price: Yup.string()
    .required('Q2 Cap Strike Price is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  q3_cap_strike_price: Yup.string()
    .required('Q3 Cap Strike Price is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  q4_cap_strike_price: Yup.string()
    .required('Q4 Cap Strike Price is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
})

const positiveOrZero = (value: string): boolean => {
  if (value === undefined || value === null || value === '') return true
  const num = parseFloat(value)
  return num >= 0
}

const capacitySchema = Yup.string()
  .required('This field is required')
  .test('min', 'Value must be between 0 and 100', (value) => parseFloat(value) >= 0)
  .test('max', 'Value must be between 0 and 100', (value) => parseFloat(value) <= 100)
  .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
  .test('positive-or-zero', 'Must be a positive number or zero', positiveOrZero)

const enablementSchema = Yup.string()
  .required('This field is required')
  .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
  .test('positive-or-zero', 'Must be a positive number or zero', positiveOrZero)
const step2ValidationSchema1 = Yup.object().shape({
  use_client_nominated_market_elasticity_factors: Yup.string(),
  market_elasticity_reg_lower: Yup.string(),
  r1_max_registered_capacity: capacitySchema.clone().required('CFCAS Market (R1) is required'),
  r6_max_registered_capacity: capacitySchema.clone().required('CFCAS Market (R6) is required'),
  r5_max_registered_capacity: capacitySchema.clone().required('CFCAS Market (R5) is required'),
  l6_max_registered_capacity: capacitySchema.clone().required('CFCAS Market (L6) is required'),
  l60_max_registered_capacity: capacitySchema.clone().required('CFCAS Market (L60) is required'),
  l1_max_registered_capacity: capacitySchema.clone().required('CFCAS Market (L1) is required'),
  r60_max_registered_capacity: capacitySchema.clone().required('CFCAS Market (R60) is required'),
  l5_max_registered_capacity: capacitySchema.clone().required('CFCAS Market (L5) is required'),

  reg_raise_max_enablement: enablementSchema.clone().required('Market(Reg raise) is required'),

  reg_lower_max_enablement: enablementSchema.clone().required('Market(Reg lower) is required'),

  l5_max_enablement: enablementSchema.clone().required('Market (L5) is required'),

  l6_max_enablement: enablementSchema.clone().required('Market (L6) is required'),

  r5_max_enablement: enablementSchema.clone().required('Market (R5) is required'),

  r6_max_enablement: enablementSchema.clone().required('Market (R6) is required'),

  r1_max_enablement: enablementSchema.clone().required('Market (R1)  is required'),
  r60_max_enablement: enablementSchema.clone().required('Market (R60) is required'),
  l1_max_enablement: enablementSchema.clone().required('Market (L1) is required'),
  l60_max_enablement: enablementSchema.clone().required('Market (L60) is required'),
  market_elasticity_wholesale: Yup.string()
    .nullable()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero', excludeEmptyString: true })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),

  r1_market_elasticity: Yup.string()
    .nullable()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero', excludeEmptyString: true })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  r6_market_elasticity: Yup.string()
    .nullable()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero', excludeEmptyString: true })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  r5_market_elasticity: Yup.string()
    .nullable()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero', excludeEmptyString: true })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),

  r60_market_elasticity: Yup.string()
    .nullable()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero', excludeEmptyString: true })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  market_elasticity_reg_raise: Yup.string()
    .nullable()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero', excludeEmptyString: true })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  l60_market_elasticity: Yup.string()
    .nullable()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero', excludeEmptyString: true })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  l1_market_elasticity: Yup.string()
    .nullable()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero', excludeEmptyString: true })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  l5_market_elasticity: Yup.string()
    .nullable()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero', excludeEmptyString: true })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  l6_market_elasticity: Yup.string()
    .nullable()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero', excludeEmptyString: true })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  loss_factors: Yup.array().of(lossFactorSchema),
  rreg_utilisation: Yup.string()
    .required('Raise Reg Utilisation is required')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number' })
    .test('positive-number', 'Must be greater than 0', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num > 0
    }),
  lreg_utilisation: Yup.string()
    .required('Lower Reg Utilisation is required')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number' })
    .test('positive-number', 'Must be greater than 0', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num > 0
    }),
  inertia_utilisation: Yup.string()
    .required('inertia_utilisation is required')
    .min(0, 'must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
})
const step3ValidationSchema = Yup.object().shape({
  Cap_contracting_is_intended: Yup.boolean(),
  cap_contracts: Yup.array().of(capContractsSchema),
})

const step3ValidationSchema1 = Yup.object().shape({
  Cap_contracting_is_intended: Yup.boolean(),
})

const step4ValidationInertia_contractSchema = Yup.object().shape({
  Inertia_contract: Yup.boolean().required(),
  is_sips_contract: Yup.boolean(),
  is_sras_contract: Yup.boolean(),
  is_vcas_contract: Yup.boolean(),
  is_non_market_service: Yup.boolean(),
  selected_inertia_constant: Yup.number().required().min(0, 'must be at least 0'),
  network_support_services: Yup.array().of(
    Yup.object().shape({
      operating_year: Yup.number().required(),
      year: Yup.number().required(),
      contracted_inertia_value: Yup.string()
        .required()
        .min(0, 'must be at least 0')
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
    }),
  ),
})

const step4Validationnon_marketSchema = Yup.object().shape({
  is_sips_contract: Yup.boolean(),
  Inertia_contract: Yup.boolean(),
  is_sras_contract: Yup.boolean(),
  is_vcas_contract: Yup.boolean(),
  is_non_market_service: Yup.boolean().required(),
  network_support_services: Yup.array().of(
    Yup.object().shape({
      operating_year: Yup.number().required(),
      year: Yup.number().required(),
      discharge_power_res_non_market_service: Yup.string()
        .required()
        .min(0, 'must be at least 0')
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
      charge_power_res_non_market_service: Yup.string()
        .required()
        .min(0, 'must be at least 0')
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
      energy_res_non_market_service: Yup.string()
        .required()
        .min(0, 'must be at least 0')
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
    }),
  ),
})

const step4Validationis_sips_contractSchema = Yup.object().shape({
  is_sips_contract: Yup.boolean().required(),
  Inertia_contract: Yup.boolean(),
  is_sras_contract: Yup.boolean(),
  is_vcas_contract: Yup.boolean(),
  is_non_market_service: Yup.boolean(),
  network_support_services: Yup.array().of(
    Yup.object().shape({
      operating_year: Yup.number().required(),
      year: Yup.number().required(),
      sips_reserved_power_capacity: Yup.string()
        .required()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
      sips_reserved_energy_storage_capacity: Yup.string()
        .required()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
      sips_contract_value: Yup.string()
        .required()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
    }),
  ),
})

const step4Validationis_sras_contractSchema = Yup.object().shape({
  is_sras_contract: Yup.boolean().required(),
  Inertia_contract: Yup.boolean(),
  is_sips_contract: Yup.boolean(),
  is_vcas_contract: Yup.boolean(),
  is_non_market_service: Yup.boolean(),
  network_support_services: Yup.array().of(
    Yup.object().shape({
      operating_year: Yup.number().required(),
      year: Yup.number().required(),
      sras_reserved_energy_storage_capacity: Yup.string()
        .required()
        .test('min', 'must be at least 0', (value) => parseFloat(value) >= 0)
        .test('integer', 'Must be an integer', (value) => {
          if (value === undefined || value === null) return true
          return Number.isInteger(parseFloat(value))
        }),
      sras_contract_value: Yup.string()
        .required()
        .test('min', 'must be at least 0', (value) => parseFloat(value) >= 0)
        .test('integer', 'Must be an integer', (value) => {
          if (value === undefined || value === null) return true
          return Number.isInteger(parseFloat(value))
        }),
    }),
  ),
})

const step4Validationis_vcas_contractSchema = Yup.object().shape({
  Inertia_contract: Yup.boolean(),
  is_sips_contract: Yup.boolean(),
  is_sras_contract: Yup.boolean(),
  is_vcas_contract: Yup.boolean().required(),
  is_non_market_service: Yup.boolean(),
  network_support_services: Yup.array().of(
    Yup.object().shape({
      operating_year: Yup.number().required(),
      year: Yup.number().required(),
      vcas_real_power_capability: Yup.string()
        .required()
        .min(0, 'must be at least 0')
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
      vcas_contract_value: Yup.string()
        .required()
        .min(0, 'must be at least 0')
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
    }),
  ),
})

const step4ValidationSchema = Yup.object().shape({
  Inertia_contract: Yup.boolean().test({
    name: 'is-true',
    exclusive: true,
    message: 'Any one network support services are intended must be checked',
    test: (value) => value === true,
  }),
  is_sips_contract: Yup.boolean().test({
    name: 'is-true',
    exclusive: true,
    message: 'Any one network support services are intended must be checked',
    test: (value) => value === true,
  }),
  is_sras_contract: Yup.boolean().test({
    name: 'is-true',
    exclusive: true,
    message: 'Any one network support services are intended must be checked',
    test: (value) => value === true,
  }),
  is_vcas_contract: Yup.boolean().test({
    name: 'is-true',
    exclusive: true,
    message: 'Any one network support services are intended must be checked',
    test: (value) => value === true,
  }),
  is_non_market_service: Yup.boolean().test({
    name: 'is-true',
    exclusive: true,
    message: 'Any one network support services are intended must be checked',
    test: (value) => value === true,
  }),
  loss_factors: Yup.array().of(nmsSchema),
})

const step4ValidationSchema1 = Yup.object().shape({
  network_support_services_intended: Yup.boolean(),
  Inertia_contract: Yup.boolean(),
  is_sips_contract: Yup.boolean(),
  is_sras_contract: Yup.boolean(),
  is_vcas_contract: Yup.boolean(),
  is_non_market_service: Yup.boolean(),
  selected_inertia_constant: Yup.number(),
  rocof_design_criteria: Yup.number(),
  network_support_services: Yup.array().of(
    Yup.object().shape({
      operating_year: Yup.number(),
      year: Yup.number(),
      raise_inertia_contract_value: Yup.string()
        .min(0, 'must be at least 0')
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
      lower_inertia_contract_value: Yup.string()
        .min(0, 'must be at least 0')
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
      contracted_raise_inertia_value: Yup.string().test('integer', 'Must be an integer', (value) => {
        if (value === undefined || value === null) return true
        return Number.isInteger(parseFloat(value))
      }),
      contracted_lower_inertia_value: Yup.string().test('integer', 'Must be an integer', (value) => {
        if (value === undefined || value === null) return true
        return Number.isInteger(parseFloat(value))
      }),
      continuous_inertia_discharge_power_reserved: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
      continuous_inertia_charge_power_reserved: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
      sips_reserved_power_capacity: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
      sips_reserved_energy_storage_capacity: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
      sips_contract_value: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
      sras_reserved_energy_storage_capacity: Yup.string()
        .min(0, 'must be at least 0')
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
      sras_contract_value: Yup.string()
        .min(0, 'must be at least 0')
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
      vcas_real_power_capability: Yup.string()
        .min(0, 'must be at least 0')
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
      vcas_contract_value: Yup.string()
        .min(0, 'must be at least 0')
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
      discharge_power_res_non_market_service: Yup.string()
        .min(0, 'must be at least 0')
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
      charge_power_res_non_market_service: Yup.string()
        .min(0, 'must be at least 0')
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
      energy_res_non_market_service: Yup.string()
        .min(0, 'must be at least 0')
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
        .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
          if (value === undefined || value === null || value === '') return true
          const num = parseFloat(value)
          return num >= 0
        }),
    }),
  ),
})

const step5commercialParametersSchema = Yup.object().shape({
  operating_year: Yup.number(),
  year: Yup.number(),
  default_warranted_cycling: Yup.string()
    .required('Default Warranted Cycling is required')
    .matches(/^[1-9]\d*(\.\d+)?$/, { message: 'Must be a positive number greater than zero' })
    .test('positive-number', 'Must be a positive number greater than zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num > 0
    }),
})

const step5bessInputsSchema = Yup.object().shape({
  operating_year: Yup.number(),
  year: Yup.number().required('Year is required'),
  energy_discharge_capacity: Yup.string()
    .required('Energy Discharge Capacity is required')
    .matches(/^[1-9]\d*(\.\d+)?$/, { message: 'Must be a positive number greater than zero' })
    .test('positive-number', 'Must be a positive number greater than zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num > 0
    }),
  rte: Yup.string()
    .required('RTE is required')
    .matches(/^[1-9]\d*(\.\d+)?$/, { message: 'Must be a positive number greater than zero' })
    .test('positive-number', 'Must be a positive number greater than zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num > 0
    }),

  transfer_efficieny: Yup.string().test('skip-validation', '', function (value) {
    const { scenario_project_type, coupled_type } = this.options.context as ValidationContext

    if (scenario_project_type === 1) {
      return true
    }

    if (scenario_project_type === 2 && coupled_type == 1) {
      if (!value) {
        return this.createError({ message: 'Cross Transfer Efficiency is required' })
      }
      const num = parseFloat(value)
      if (num <= 0) {
        return this.createError({ message: 'Must be a positive number greater than zero' })
      }
    }

    return true
  }),

  generation_efficieny: Yup.string().test('skip-validation', '', function (value) {
    const { scenario_project_type } = this.options.context as ValidationContext

    if (scenario_project_type === 1) {
      return true
    }

    if (scenario_project_type === 2) {
      if (!value) {
        return this.createError({ message: 'Generation Efficiency is required' })
      }
      const num = parseFloat(value)
      if (num <= 0) {
        return this.createError({ message: 'Must be a positive number greater than zero' })
      }
    }

    return true
  }),

  ac_charging_efficieny: Yup.string().test('skip-validation', '', function (value) {
    const { scenario_project_type } = this.options.context as ValidationContext

    if (scenario_project_type === 1) {
      return true
    }

    if (scenario_project_type === 2) {
      if (!value) {
        return this.createError({ message: 'AC Charging Efficiency is required' })
      }
      const num = parseFloat(value)
      if (num <= 0) {
        return this.createError({ message: 'Must be a positive number greater than zero' })
      }
    }

    return true
  }),
  dc_charging_efficieny: Yup.string().test('skip-validation', '', function (value) {
    const { scenario_project_type, coupled_type } = this.options.context as ValidationContext

    if (scenario_project_type === 1) {
      return true
    }

    if (scenario_project_type === 2 && coupled_type === 2) {
      if (!value) {
        return this.createError({ message: 'DC Charging Efficiency is required' })
      }

      const num = parseFloat(value)
      if (num <= 0) {
        return this.createError({ message: 'Must be a positive number greater than zero' })
      }
    }

    return true
  }),
})

const step5ValidationSchema = Yup.object().shape({
  standby_power_consumption: Yup.number()
    .required('Standby Power Consumption is required')
    .min(0, 'Must be a positive number or zero'),
  default_warrant_cycle: Yup.number()
    .required('Default Warranted Cycling is required')
    .min(0, 'Must be a positive number or zero'),
  commercial_parameters: Yup.array().of(step5commercialParametersSchema),
  bess_inputs: Yup.array().of(step5bessInputsSchema),
})

const step5ValidationSchema1 = Yup.object().shape({
  standby_power_consumption: Yup.number(),
  default_warrant_cycle: Yup.number(),
  commercial_parameters: Yup.array().of(step5commercialParametersSchema),
  bess_inputs: Yup.array().of(step5bessInputsSchema),
})

const capxValuesSchema = Yup.object().shape({
  year: Yup.string()
    .required('Year is required')
    .matches(/^\d{4}$/, 'Year must be a 4-digit year'),
  capex_estimate: Yup.string()
    .required('Value is required')
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
})

const repexValuesSchema = Yup.object().shape({
  operating_year: Yup.number().required(),
  year: Yup.string()
    .required('Year is required')
    .matches(/^\d{4}$/, 'Year must be a 4-digit year'),

  mid_life_repex: Yup.string()
    .required('Value is required')
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
})

const capxValuesSchema1 = Yup.object().shape({
  year: Yup.string()
    .required('Year is required')
    .matches(/^\d{4}$/, 'Year must be a 4-digit year'),
  capex_estimate: Yup.string()
    .required('Value is required')
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number 1 or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
})

const repexValuesSchema1 = Yup.object().shape({
  operating_year: Yup.number(),
  year: Yup.string()
    .required('Year is required')
    .matches(/^\d{4}$/, 'Year must be a 4-digit year'),
  mid_life_repex: Yup.string()
    .required('Value is required')
    .min(0, 'Must be at least 0')
    .notRequired()
    .test('integer', 'Must be an integer', (value) => {
      if (value === undefined || value === null) return true
      return Number.isInteger(parseFloat(value))
    }),
})

const commercialParametersSchema1 = Yup.object().shape({
  operating_year: Yup.number(),
  year: Yup.number(),
  long_term_service_agreement_cost_percentage: Yup.string()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  manual_long_term_service_agreement_cost: Yup.string()
    .nullable()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  warranty_cost_percentage: Yup.string()
    .min(0, 'Must be at least 0')
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    })
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' }),
  manual_capex_estimate: Yup.string(),
  manual_warranty_cost_absolute: Yup.string()
    .notRequired()
    .nullable()
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  lgc_price: Yup.string()
    .nullable()
    .min(0, 'Must be at least 0')
    .notRequired()
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  tuos_cost: Yup.string()
    .nullable()
    .min(0, 'Must be at least 0')
    .notRequired()
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  other_o_and_m_cost: Yup.string()
    .nullable()
    .min(0, 'Must be at least 0')
    .notRequired()
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
})

const commercialParametersSchema = Yup.object().shape({
  operating_year: Yup.number().required(),
  year: Yup.number().required(),
  long_term_service_agreement_cost_percentage: Yup.string()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  manual_long_term_service_agreement_cost: Yup.string()
    .nullable()
    .notRequired()
    .min(0, 'Must be at least 0')
    .test('integer', 'Must be an integer', (value) => {
      if (value === undefined || value === null) return true
      return Number.isInteger(parseFloat(value))
    }),
  warranty_cost_percentage: Yup.string()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  manual_warranty_cost_absolute: Yup.string()
    .nullable()
    .notRequired()
    .test('integer', 'Must be an integer', (value) => {
      if (value === undefined || value === null) return true
      return Number.isInteger(parseFloat(value))
    }),
  manual_capex_estimate: Yup.string(),
  lgc_price: Yup.string()
    .nullable()
    .min(0, 'Must be at least 0')
    .notRequired()
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  tuos_cost: Yup.string()
    .nullable()
    .notRequired()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  other_o_and_m_cost: Yup.string()
    .nullable()
    .notRequired()
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
})

const step6ValidationSchema = Yup.object().shape({
  o_and_m_inflation_rate: Yup.string()
    .required('O&M Inflation rate is required')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .min(0, 'Must be at least 0')
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  pop_wholesale_energy_generation: Yup.string()
    .required('Wholesale energy generation is required')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .min(0, 'Must be at least 0')
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  pop_wholesale_energy_load: Yup.string()
    .required('Wholesale energy load is required')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .min(0, 'Must be at least 0')
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  pop_reg_fcas: Yup.string()
    .required('Pop reg FCAS is required')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .min(0, 'Must be at least 0')
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  pop_contingency_fcas: Yup.string()
    .required('Pop contingency FCAS is required')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .min(0, 'Must be at least 0')
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  standard_inflation_rate: Yup.string()
    .required('Standard inflation rate is required')
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  npv_discount_rate: Yup.string()
    .required('Discount rate is required')
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  capex: Yup.array().of(capxValuesSchema).required(),
  repex: Yup.array()
    .of(repexValuesSchema)
    .test('unique-year', 'Each year must be unique', function (value) {
      if (!value) return true
      const years = value.map((item) => item.year)
      const uniqueYears = new Set(years)
      return uniqueYears.size === years.length
    })
    .required(),
  commercial_parameters: Yup.array().of(commercialParametersSchema),
  is_manual_ltsa_input: Yup.boolean().required(),
  is_manual_warranty_input: Yup.boolean().required(),
})

const step6ValidationSchema1 = Yup.object().shape({
  o_and_m_inflation_rate: Yup.string()
    .required('O&M Inflation rate is required')
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  pop_wholesale_energy_generation: Yup.string()
    .required('Wholesale energy generation is required')
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  pop_wholesale_energy_load: Yup.string()
    .required('Wholesale energy load is required')
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  pop_reg_fcas: Yup.string()
    .required('Pop reg FCAS is required')
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  pop_contingency_fcas: Yup.string()
    .required('Pop contingency FCAS is required')
    .min(0, 'Must be at least 0')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  standard_inflation_rate: Yup.string()
    .min(0, 'Must be at least 0')
    .required('Standard inflation rate is required')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  npv_discount_rate: Yup.string()
    .min(0, 'Must be at least 0')
    .required('Discount rate is required')
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, { message: 'Must be a positive number or zero' })
    .test('positive-or-zero', 'Must be a positive number or zero', (value) => {
      if (value === undefined || value === null || value === '') return true
      const num = parseFloat(value)
      return num >= 0
    }),
  capex: Yup.array().of(capxValuesSchema1),
  repex: Yup.array()
    .of(repexValuesSchema1)
    .test('unique-year', 'Each year must be unique', function (value) {
      if (!value) return true
      const years = value.map((item) => item.year)
      const uniqueYears = new Set(years)
      return uniqueYears.size === years.length
    }),
  commercial_parameters: Yup.array().of(commercialParametersSchema1),
  is_manual_ltsa_input: Yup.boolean(),
  is_manual_warranty_input: Yup.boolean(),
})

const isStep1FormData = (data: FormData): data is ScenarioFormData => {
  return (
    'scenario_project_type' in data &&
    'hybrid_generation_type' in data &&
    'coupled_type' in data &&
    'max_bess_inverter_capacity_out' in data &&
    'max_bess_inverter_capacity_in' in data &&
    'max_vre_inverter_capacity_out' in data &&
    'max_energy_output_to_grid' in data &&
    'max_energy_input_from_grid' in data &&
    'project_title' in data &&
    'project_life_years' in data &&
    'first_year_of_commercial_operation' in data &&
    'inverter_capacity' in data &&
    'solve_interval_hours' in data &&
    'window_hours' in data &&
    'time_res' in data
  )
}

const isStep2FormData = (data: FormData): data is ScenarioFormData => {
  const requiredProperties = [
    'r1_max_registered_capacity',
    'r6_max_registered_capacity',
    'r60_max_registered_capacity',
    'r5_max_registered_capacity',
    'l1_max_registered_capacity',
    'l6_max_registered_capacity',
    'l60_max_registered_capacity',
    'l5_max_registered_capacity',
    'market_elasticity_wholesale',
    'market_elasticity_reg_lower',
    'market_elasticity_reg_raise',
    'r1_market_elasticity',
    'r6_market_elasticity',
    'r5_market_elasticity',
    'r60_market_elasticity',
    'l1_market_elasticity',
    'l6_market_elasticity',
    'l5_market_elasticity',
    'l60_market_elasticity',
    'reg_raise_max_enablement',
    'reg_lower_max_enablement',
    'r1_max_enablement',
    'r6_max_enablement',
    'r60_max_enablement',
    'r5_max_enablement',
    'l1_max_enablement',
    'l6_max_enablement',
    'l60_max_enablement',
    'l5_max_enablement',
    'rreg_utilisation',
    'lreg_utilisation',
    'inertia_utilisation',
  ]

  const hasAllProperties = requiredProperties.every((prop) => prop in data)

  const hasValidLossFactors =
    Array.isArray(data.loss_factors) &&
    data.loss_factors.every(
      (contract) =>
        typeof contract === 'object' &&
        'operating_year' in contract &&
        'generation_mlf' in contract &&
        'load_mlf' in contract,
    )

  return hasAllProperties && hasValidLossFactors
}
const isStep2FormData1 = (data: FormData): data is ScenarioFormData => {
  return (
    'r1_max_registered_capacity' in data &&
    'r6_max_registered_capacity' in data &&
    'r60_max_registered_capacity' in data &&
    'r5_max_registered_capacity' in data &&
    'l1_max_registered_capacity' in data &&
    'l6_max_registered_capacity' in data &&
    'l60_max_registered_capacity' in data &&
    'l5_max_registered_capacity' in data &&
    'reg_raise_max_enablement' in data &&
    'reg_lower_max_enablement' in data &&
    'r1_max_enablement' in data &&
    'r6_max_enablement' in data &&
    'r60_max_enablement' in data &&
    'r5_max_enablement' in data &&
    'l1_max_enablement' in data &&
    'l6_max_enablement' in data &&
    'l60_max_enablement' in data &&
    'l5_max_enablement' in data &&
    'rreg_utilisation' in data &&
    'lreg_utilisation' in data &&
    'inertia_utilisation' in data &&
    data.loss_factors.every(
      (contract) =>
        typeof contract === 'object' &&
        'operating_year' in contract &&
        'generation_mlf' in contract &&
        'load_mlf' in contract,
    )
  )
}

const isStep3FormData = (data: FormData): data is ScenarioFormData => {
  return (
    typeof data === 'object' &&
    'Cap_contracting_is_intended' in data &&
    'strike_price' in data &&
    'cap_contracts' in data &&
    Array.isArray(data.cap_contracts) &&
    data.cap_contracts.every(
      (contract) =>
        typeof contract === 'object' &&
        'year' in contract &&
        'q1_cap_volume' in contract &&
        'q1_cap_price' in contract &&
        'q2_cap_volume' in contract &&
        'q2_cap_price' in contract &&
        'q3_cap_volume' in contract &&
        'q3_cap_price' in contract &&
        'q4_cap_volume' in contract &&
        'q4_cap_price' in contract,
    )
  )
}

const isStep4FormData = (data: FormData): data is ScenarioFormData => {
  if (data.network_support_services_intended && data.Inertia_contract) {
    return (
      typeof data === 'object' &&
      'Inertia_contract' in data &&
      'selected_inertia_constant' in data &&
      Array.isArray(data.network_support_services) &&
      data.network_support_services.every(
        (contract) =>
          typeof contract === 'object' &&
          'operating_year' in contract &&
          'year' in contract &&
          'contracted_inertia_value' in contract,
      )
    )
  }

  if (data.network_support_services_intended && data.is_sips_contract) {
    return (
      typeof data === 'object' &&
      'is_sips_contract' in data &&
      Array.isArray(data.network_support_services) &&
      data.network_support_services.every(
        (contract) =>
          typeof contract === 'object' &&
          'operating_year' in contract &&
          'year' in contract &&
          'sips_reserved_power_capacity' in contract &&
          'sips_reserved_energy_storage_capacity' in contract &&
          'sips_contract_value' in contract,
      )
    )
  }

  if (data.network_support_services_intended && data.is_non_market_service) {
    return (
      typeof data === 'object' &&
      'is_non_market_service' in data &&
      Array.isArray(data.network_support_services) &&
      data.network_support_services.every(
        (contract) =>
          typeof contract === 'object' &&
          'operating_year' in contract &&
          'year' in contract &&
          'discharge_power_res_non_market_service' in contract &&
          'charge_power_res_non_market_service' in contract &&
          'energy_res_non_market_service' in contract,
      )
    )
  }

  if (data.network_support_services_intended && data.is_sras_contract) {
    return (
      typeof data === 'object' &&
      'is_sras_contract' in data &&
      Array.isArray(data.network_support_services) &&
      data.network_support_services.every(
        (contract) =>
          typeof contract === 'object' &&
          'operating_year' in contract &&
          'year' in contract &&
          'sras_reserved_energy_storage_capacity' in contract &&
          'sras_contract_value' in contract,
      )
    )
  }

  if (data.network_support_services_intended && data.is_vcas_contract) {
    return (
      typeof data === 'object' &&
      'is_vcas_contract' in data &&
      Array.isArray(data.network_support_services) &&
      data.network_support_services.every(
        (contract) =>
          typeof contract === 'object' &&
          'operating_year' in contract &&
          'year' in contract &&
          'vcas_real_power_capability' in contract &&
          'vcas_contract_value' in contract,
      )
    )
  }

  return (
    typeof data === 'object' &&
    'is_sips_contract' in data &&
    'is_non_market_service' in data &&
    'is_sras_contract' in data &&
    'is_vcas_contract' in data
  )
}

const isStep5FormData = (data: FormData): data is ScenarioFormData => {
  return (
    typeof data === 'object' &&
    'standby_power_consumption' in data &&
    'default_warrant_cycle' in data &&
    Array.isArray(data.bess_inputs) &&
    data.bess_inputs.every(
      (input) =>
        typeof input === 'object' &&
        'year' in input &&
        'energy_discharge_capacity' in input &&
        'rte' in input &&
        'correction_factor_half_cycle' in input &&
        'correction_factor_one_cycle' in input &&
        'correction_factor_one_and_half_cycle' in input &&
        'correction_factor_two_cycle' in input &&
        'generation_efficieny' in input &&
        'ac_charging_efficieny' in input &&
        'dc_charging_efficieny' in input &&
        'transfer_efficieny' in input,
    )
  )
}

const isStep6FormData = (data: FormData): data is ScenarioFormData => {
  return (
    typeof data === 'object' &&
      'o_and_m_inflation_rate' in data &&
      'pop_wholesale_energy_generation' in data &&
      'pop_wholesale_energy_load' in data &&
      'pop_reg_fcas' in data &&
      'pop_contingency_fcas' in data &&
      'standard_inflation_rate' in data &&
      'npv_discount_rate' in data &&
      Array.isArray(data.repex) &&
      data.repex.every((input) => typeof input === 'object' && 'operating_year' in input && 'mid_life_repex' in input),
    Array.isArray(data.capex) &&
      data.capex.every((input) => typeof input === 'object' && 'operating_year' in input && 'capex_estimate' in input),
    Array.isArray(data.commercial_parameters) &&
      data.commercial_parameters.every(
        (input) =>
          typeof input === 'object' &&
          'operating_year' in input &&
          'long_term_service_agreement_cost_percentage' in input &&
          'manual_long_term_service_agreement_cost' in input &&
          'warranty_cost_percentage' in input &&
          'manual_warranty_cost_absolute' in input &&
          'manual_capex_estimate' in input &&
          'tuos_cost' in input &&
          'lg_price' in input &&
          'other_o_and_m_cost' in input,
      )
  )
}

const formatYupError = (error: Yup.ValidationError): Record<string, string> => {
  const errors: Record<string, string> = {}

  if (error.inner && error.inner.length > 0) {
    error.inner.forEach((innerError) => {
      if (innerError.path) {
        errors[innerError.path] = innerError.message
      }
    })
  } else {
    const errorPath = error.path as string
    const errorMessage = error.message as string
    errors[errorPath] = errorMessage
  }

  return errors
}

const getValidationSchemaForStep1 = (data: ScenarioFormData) => {
  return isStep1FormData(data) ? step1ValidationSchema : null
}

const getValidationSchemaForStep2 = (data: ScenarioFormData) => {
  if (isStep2FormData(data)) {
    return step2ValidationSchema
  } else if (isStep2FormData1(data)) {
    return step2ValidationSchema1
  } else {
    return null
  }
}

const getValidationSchemaForStep3 = (data: ScenarioFormData) => {
  return data.Cap_contracting_is_intended && isStep3FormData(data) ? step3ValidationSchema : step3ValidationSchema1
}

const getValidationSchemaForStep4 = (data: ScenarioFormData) => {
  if (data.network_support_services_intended && isStep4FormData(data)) {
    if (data.Inertia_contract) {
      return step4ValidationInertia_contractSchema
    } else if (data.is_non_market_service) {
      return step4Validationnon_marketSchema
    } else if (data.is_sips_contract) {
      return step4Validationis_sips_contractSchema
    } else if (data.is_sras_contract) {
      return step4Validationis_sras_contractSchema
    } else if (data.is_vcas_contract) {
      return step4Validationis_vcas_contractSchema
    } else {
      return step4ValidationSchema
    }
  } else {
    return step4ValidationSchema1
  }
}

const getValidationSchemaForStep5 = (data: ScenarioFormData) => {
  return isStep5FormData(data) ? step5ValidationSchema : step5ValidationSchema1
}

const getValidationSchemaForStep6 = (data: ScenarioFormData) => {
  return isStep6FormData(data) ? step6ValidationSchema : step6ValidationSchema1
}

const getValidationSchema = (step: number, data: ScenarioFormData) => {
  switch (step) {
    case 1:
      return getValidationSchemaForStep1(data)
    case 2:
      return getValidationSchemaForStep2(data)
    case 3:
      return getValidationSchemaForStep3(data)
    case 4:
      return getValidationSchemaForStep4(data)
    case 5:
      return getValidationSchemaForStep5(data)
    case 6:
      return getValidationSchemaForStep6(data)
    default:
      return null
  }
}

export const validateStepData = async (
  step: number,
  data: ScenarioFormData,
): Promise<Record<string, string> | null> => {
  const schema = getValidationSchema(step, data)

  if (!schema) {
    throw new Error(`Invalid data for step ${step}`)
  }

  try {
    const context: ValidationContext = {
      scenario_project_type: Number(data.scenario_project_type),
      coupled_type: data.coupled_type ? Number(data.coupled_type) : undefined,
    }
    await schema.validate(data, { abortEarly: false, context })
    return null
  } catch (validationErrors) {
    const formatErrors = (errors: Yup.ValidationError | Yup.ValidationError[]) => {
      const formattedErrors: Record<string, string> = {}
      ;(Array.isArray(errors) ? errors : [errors]).forEach((error: Yup.ValidationError) => {
        Object.assign(formattedErrors, formatYupError(error))
      })
      return formattedErrors
    }

    return formatErrors(validationErrors as Yup.ValidationError)
  }
}
