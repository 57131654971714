import React from 'react'
import CommercialPerformanceDataTable from './CommercialPerformanceDataTable'
import { Reportscenariomapping } from 'src/models/api/ReportDetailResponeModal'
import { CommercialPerformanceDataTableHeadersLabels } from 'src/enums/ReportsEnum'

interface ViewCommercialPerformanceProps {
  scenarioData: Reportscenariomapping[]
}

const ViewCommercialPerformance: React.FC<ViewCommercialPerformanceProps> = ({ scenarioData }) => {
  const viewcommercialPerformanceData = scenarioData.map((item) => {
    const total_net_revenue = item?.total_net_revenue
    const net_present_value = item?.net_present_value
    const internal_rate_of_return = item.internal_rate_of_return

    return [
      { label: 'Total net revenue', value: total_net_revenue },
      { label: 'Net present value', value: net_present_value },
      { label: 'Internal Rate of Return', value: internal_rate_of_return },
    ]
  })

  const labels = CommercialPerformanceDataTableHeadersLabels
  console.log(scenarioData)
  return (
    <CommercialPerformanceDataTable
      headers={[
        'Commercial Performance',
        ...scenarioData.map((scenariObj) => scenariObj?.scenario?.name || 'Unnamed Scenario'),
      ]}
      data={viewcommercialPerformanceData}
      labels={labels}
    />
  )
}

export default ViewCommercialPerformance
