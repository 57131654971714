import React, { useEffect, useState } from 'react'
import { Container, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import Style from '../../../styles/Reports.module.sass'
import { Reportscenariomapping } from 'src/models/api/ReportDetailResponeModal'

interface ViewScenarioSummaryProps {
  scenarioData: Reportscenariomapping[]
}

interface ViewScenarioSummaryItem {
  label: string
  value: string | number | JSX.Element
}

const ViewScenarioSummary: React.FC<ViewScenarioSummaryProps> = ({ scenarioData }) => {
  const [summaryData, setSummaryData] = useState<(ViewScenarioSummaryItem | false)[][]>([])

  const labels = [
    'Discharge Power',
    'Charge Power',
    'BOL Storage Capacity',
    'Inertia scenario',
    'Cap Contract',
    'Non Market Service Scenario',
  ]

  useEffect(() => {
    const data = scenarioData.map((scenario: Reportscenariomapping) => [
      { label: 'Discharge Power', value: scenario.discharge_power ? scenario.discharge_power : '0' },
      { label: 'Charge Power', value: scenario.charge_power ? scenario.charge_power : '0' },
      { label: 'BOL Storage Capacity', value: scenario.bol_storage_capacity ? scenario.bol_storage_capacity : '0' },
      { label: 'Inertia scenario', value: scenario.inertia_scenario.toString() },
      { label: 'Cap Contract', value: scenario.cap_contract.toString() },
      { label: 'Non Market Service Scenario', value: scenario.non_market_service.toString() },
    ])

    setSummaryData(data)
  }, [scenarioData])

  return (
    <Container fluid>
      <div className={Style.tbl_container}>
        <Table
          headers={[
            { label: 'Scenario Summary' },
            ...scenarioData.map((scenariObj) => ({ label: `${scenariObj?.scenario?.name}` })),
          ]}
        >
          {labels.map((label, rowIndex) => (
            <TableRow key={label}>
              <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
                <p>{label}</p>
              </TableCell>
              {summaryData.map((row, colIndex) => {
                const cellData = row[rowIndex]
                return (
                  <TableCell cellClass={Style.tableDataPadding} key={`${label}-${colIndex}`}>
                    {cellData ? cellData.value : '-'}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </Table>
      </div>
    </Container>
  )
}

export default ViewScenarioSummary
