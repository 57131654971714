import {
  Button,
  Container,
  DateInput,
  Dropdown,
  FormInput,
  Grid,
  Icon,
  InfoTooltip,
  TextTooltip,
  useToast,
} from '@aurecon-creative-technologies/styleguide'
import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue } from 'recoil'
import { appInsights } from 'src/api/AppInsights'
import {
  downloadTemplateFile,
  fetchForecastingDetails,
  fetchTablePreviewData,
  postMultiFormData,
  putMultiFormData,
} from 'src/api/ForecastService'
import LoadingScreen from 'src/components/LoadingScreen'
import { ERROR_MESSAGES } from 'src/enums/validationConstants'
import { ForecastingTableModelItem } from 'src/models/api/ForecastingTableModel'
import {
  forecastDataSelector,
  getAllForecastData,
  inProductedSecretState,
  selectedProjectIdState,
  uploadFormState,
} from 'src/stores/AppStore'
import * as Yup from 'yup'
import { ReactComponent as FileIcon } from '../../assets/fileicon.svg'
import Style from '../../styles/UploadForecast.module.sass'
import TablePreview from './TablePreview'
import { isRoleEmpty } from 'src/helpers/appRoles'
const uploadForecastFileTypes = ['.csv']

const forecastIntervalHeader = [
  { id: 5, label: '5 minutes' },
  { id: 10, label: '10 minutes' },
  { id: 15, label: '15 minutes' },
  { id: 30, label: '30 minutes' },
  { id: 60, label: '60 minutes' },
]

const validationSchemas = {
  create: Yup.object().shape({
    description: Yup.string().max(5000, ERROR_MESSAGES.descriptionMax).required(ERROR_MESSAGES.requiredField),
    period: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? undefined : value))
      .integer(ERROR_MESSAGES.numberInteger)
      .min(1, ERROR_MESSAGES.numberMin)
      .required(ERROR_MESSAGES.requiredField),
    interval: Yup.string().required(ERROR_MESSAGES.requiredField),
    start_date: Yup.string().required(ERROR_MESSAGES.requiredField),
    file: Yup.mixed<File>()
      .test('fileType', `Unsupported file type. Supported types: ${uploadForecastFileTypes.join(', ')}`, (value) => {
        if (!value) return true
        const file = value as File
        const fileType = `.${file.name.split('.').pop()?.toLowerCase()}`
        return uploadForecastFileTypes.includes(fileType)
      })
      .required(ERROR_MESSAGES.requiredFile),
  }),
  edit: Yup.object().shape({
    description: Yup.string().max(5000, ERROR_MESSAGES.descriptionMax).required(ERROR_MESSAGES.requiredField),
    period: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? undefined : value))
      .integer(ERROR_MESSAGES.numberInteger)
      .min(1, ERROR_MESSAGES.numberMin)
      .required(ERROR_MESSAGES.requiredField),
    interval: Yup.string().required(ERROR_MESSAGES.requiredField),
    start_date: Yup.string().required(ERROR_MESSAGES.requiredField),
    file: Yup.mixed<File>()
      .nullable()
      .test('fileType', `Unsupported file type. Supported types: ${uploadForecastFileTypes.join(', ')}`, (value) => {
        if (!value) return true
        const file = value as File
        const fileType = `.${file.name.split('.').pop()?.toLowerCase()}`
        return uploadForecastFileTypes.includes(fileType)
      }),
  }),
}

interface UploadForecastProps {
  isEdit?: boolean
}

const UploadForecast: React.FC<UploadForecastProps> = ({ isEdit = false }) => {
  if (appInsights) {
    appInsights.trackPageView({ name: isEdit ? 'EditForecast' : 'CreateForecast' })
  }
  const SecretState = useRecoilValue(inProductedSecretState)
  const showToast = useToast()
  const navigate = useNavigate()
  const { forecastId: initialForecastId } = useParams<{ forecastId?: string }>()
  const [formData, setFormData] = useRecoilState(uploadFormState)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [progressPercentage, setProgressPercentage] = useState<number>()
  const refreshFetchItems = useRecoilRefresher_UNSTABLE(forecastDataSelector)
  const refreshFetchAllForecastItems = useRecoilRefresher_UNSTABLE(getAllForecastData)
  const projectId = useRecoilValue(selectedProjectIdState)
  const [tableData, setTableData] = useState<ForecastingTableModelItem[] | null>(null)
  const [loadingText, setLoadingText] = useState('')

  const [errors, setErrors] = useState({
    description: '',
    file: '',
    interval: '',
    start_date: '',
    period: '',
    is_leap_year_included: '',
  })

  const resetFormData = useCallback(() => {
    setFormData({
      description: '',
      name: '',
      interval: '',
      start_date: '',
      period: '',
      profile_type: null,
      is_leap_year_included: false,
    })
  }, [setFormData])

  const fetchForecastData = useCallback(
    async (forecastId: string) => {
      setLoading(true)
      try {
        const response = await fetchForecastingDetails(forecastId)
        if (response) {
          setFormData({
            description: response?.['description'],
            name: response?.['name'],
            is_leap_year_included: response?.['is_leap_year_included'],
            period: response?.['period'],
            start_date: response?.['start_date'],
            interval: response?.['interval'],
            profile_type: null,
          })

          if (response?.['file_processing_status']['name']) {
            const tablePreviewResponse = await fetchTablePreviewData(Number(initialForecastId))
            if (tablePreviewResponse) {
              setTableData(tablePreviewResponse)
            }
          }
        }
      } catch (error) {
        showToast.addToast({ type: 'error', message: 'Failed to fetch forecast data!', timeout: 3000 })
      } finally {
        setLoading(false)
      }
    },
    [setFormData, showToast, initialForecastId],
  )

  useEffect(() => {
    if (!isEdit) {
      resetFormData()
    } else if (isEdit && initialForecastId) {
      fetchForecastData(initialForecastId)
    }
  }, [isEdit, initialForecastId, resetFormData, fetchForecastData])

  const handleForecastSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    setLoadingText(isEdit ? 'Updating Forecast Data' : 'Uploading Forecast File')

    if (selectedFile) {
      setFormData((prev) => ({ ...prev, name: selectedFile.name }))
    }

    let newErrors = {
      description: '',
      file: '',
      interval: '',
      start_date: '',
      period: '',
      is_leap_year_included: '',
    }

    try {
      const validationSchema = isEdit ? validationSchemas.edit : validationSchemas.create

      await validationSchema.validate(
        {
          description: formData.description,
          period: formData.period,
          start_date: formData.start_date,
          interval: formData.interval,
          profile_type: formData.profile_type,
          file: selectedFile,
        },
        { abortEarly: false },
      )

      if (!isEdit && !selectedFile) {
        newErrors.file = 'Please select a file'
        setErrors(newErrors)
        return
      }

      setErrors({
        description: '',
        file: '',
        period: '',
        start_date: '',
        interval: '',
        is_leap_year_included: '',
      })
      setLoading(true)
      const response = isEdit
        ? await putMultiFormData(initialForecastId, projectId, formData)
        : await postMultiFormData(projectId, formData, selectedFile, onUploadProgress)
      if (response.success || response.status === 200 || response.status === 201) {
        refreshFetchAllForecastItems()
        showToast.addToast({
          type: 'success',
          message: `Forecast ${isEdit ? 'updated' : 'uploaded'} successfully`,
          timeout: 3000,
        })
        navigate(`/dashboard/${projectId}/forecasting`)
      } else {
        showToast.addToast({ type: 'error', message: 'Failed to upload file!', timeout: 3000 })
        setSelectedFile(null)
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((err) => {
          if (err.path) {
            newErrors = { ...newErrors, [err.path]: err.message }
          }
        })
        setErrors(newErrors)
      } else {
        showToast.addToast({ type: 'error', message: 'An error occurred while uploading the file!', timeout: 3000 })
        setSelectedFile(null)
      }
    } finally {
      refreshFetchItems()
      setLoading(false)
    }
  }

  const handleForecastLeapYearChange = (value: string) => {
    let isLeapYearIncluded: boolean | null = null
    if (value === 'yes') {
      isLeapYearIncluded = true
    } else if (value === 'no') {
      isLeapYearIncluded = false
    }
    setFormData((prev) => ({ ...prev, is_leap_year_included: isLeapYearIncluded }))
    setErrors((prev) => ({ ...prev, is_leap_year_included: '' }))
  }

  const handleChange = (fieldName: string) => (value: string | boolean) => {
    setFormData((prev) => ({ ...prev, [fieldName]: value }))
    setErrors((prev) => ({ ...prev, [fieldName]: '' }))
  }

  const handleForecastIntervalChange = (value: string | number, interval: string) => {
    setFormData((prev) => ({ ...prev, [interval]: value }))
    setErrors((prev) => ({ ...prev, [interval]: '' }))
  }

  const handleForecastDateChange = async (dates: { startDate: Date | null; endDate: Date | null }) => {
    const preferredDate = dates.startDate
    const adjustedDate = preferredDate
      ? new Date(preferredDate.getTime() - preferredDate.getTimezoneOffset() * 60000)
      : ''
    setFormData((prev) => ({
      ...prev,
      start_date: adjustedDate ? adjustedDate.toISOString().split('T')[0] : '',
    }))
  }

  const handleGoBackClick = useCallback(() => {
    navigate(`/dashboard/${projectId}/forecasting`)
  }, [navigate, projectId])

  const onUploadProgress = (progress: number) => {
    setProgressPercentage(progress)
  }

  const handleDownloadTemplate = async () => {
    try {
      await downloadTemplateFile('NEM_FORECAST_TEMPLATE.csv', (progress) => {
        setProgressPercentage(progress)
      })
    } catch (error) {
      console.error('Failed to download :', error, progressPercentage)
      setProgressPercentage(0)
    }
  }

  const handleForecastImageUpload = (files: FileList | null) => {
    if (files) {
      const fileArray = Array.from(files)
      setSelectedFile(fileArray.length > 0 ? fileArray[0] : null)
    } else {
      setSelectedFile(null)
    }
  }

  const handleFileDrop = (e: React.DragEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    if (file) {
      setSelectedFile(file)
    }
  }

  const handleDragOver = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
  }

  const handleClearPic = () => {
    setSelectedFile(null)
  }

  useEffect(() => {
    if (selectedFile) {
      setPreviewUrl(URL.createObjectURL(selectedFile))
    } else {
      setPreviewUrl(null)
    }
  }, [selectedFile])

  return (
    <div>
      {loading ? (
        <LoadingScreen text={loadingText} progress={isEdit ? undefined : progressPercentage} />
      ) : (
        <>
          <div className={Style.mainBlock}>
            <div className={Style.goBack}>
              <Button
                type='custom'
                cssClass={Style.goBackBtn}
                onClick={handleGoBackClick}
                label='Go back'
                icon='arrow_back_ios'
                size='small'
              />
              <h2 className={Style.titleHead}>{isEdit ? 'Edit Forecast Data' : 'Upload Forecast Data'}</h2>
            </div>
          </div>
          <Container fluid cssClass={Style.paddingX} style={{ gap: '20px' }}>
            <div className={Style.projectForm}>
              <div className={Style.generateProfileInfo}>
                <div className={Style.firstLine}>
                  <Icon type='info' size='24px' className={Style.infoIcon} outlined />
                  Please ensure that the uploaded file contains the following column headers, The Column headers must
                  exactly match for the file to be processed.
                </div>
                <div className={Style.secondLine}>
                  DateTime - Date and Time of the price in YYYY-MM-DD hh:mm:ss format
                </div>
                <div className={Style.thirdLine}>ENERGY - Wholesale market energy price</div>
                <div className={Style.fourthLine}>
                  <span className={Style.fillingInstruction}>L5m - L5 Market energy price</span>
                  <span className={Style.fillingInstruction}>L60 - L60 Market energy price</span>
                  <span className={Style.fillingInstruction}>L6 - L6 Market energy price</span>
                  <span className={Style.fillingInstruction}>L1 - L1 Market energy price</span>
                  <span className={Style.fillingInstruction}>LREG - LREG Market energy price</span>
                </div>
                <div className={Style.fifthLine}>
                  <span className={Style.fillingInstruction}>R5m - R5 Market energy price</span>
                  <span className={Style.fillingInstruction}>R60 - R60 Market energy price</span>
                  <span className={Style.fillingInstruction}>R6 - R6 Market energy price</span>
                  <span className={Style.fillingInstruction}>R1 - R1 Market energy price</span>
                  <span className={Style.fillingInstruction}>RREG - RREG Market energy price</span>
                </div>
              </div>
              <Grid row gap={12}>
                <Grid item lg={4}>
                  <div className={classNames(Style.formGroup, 'input_forms')}>
                    <Dropdown
                      label='Interval'
                      items={forecastIntervalHeader}
                      selectedItem={formData.interval}
                      onSelectItem={(v) => handleForecastIntervalChange(v, 'interval')}
                      required={true}
                      optionsHeight={200}
                    />
                    {errors.interval && <p className={Style.error}>{errors.interval}</p>}
                  </div>
                </Grid>
                <Grid item lg={4}>
                  <div className={classNames(Style.formGroup, 'input_forms')}>
                    <DateInput
                      label='Start Date'
                      cssClass='reportDate'
                      required
                      dates={{
                        startDate: formData.start_date ? new Date(formData.start_date) : null,
                        endDate: formData.start_date ? new Date(formData.start_date) : null,
                      }}
                      onDateChange={handleForecastDateChange}
                    />
                    {errors.start_date && <p className={Style.error}>{errors.start_date}</p>}
                  </div>
                </Grid>
                <Grid item lg={4}>
                  <FormInput
                    type='number'
                    key='period'
                    label='Period'
                    value={formData.period.toString()}
                    onChange={handleChange('period')}
                    required
                    error={errors.period}
                  />
                </Grid>

                <Grid item lg={12}>
                  <div className={Style.formGroup}>
                    <FormInput
                      type='multiline'
                      key='description'
                      label='Description'
                      placeholder='Please provide descriptions for this forecast...'
                      value={formData.description}
                      onChange={handleChange('description')}
                      required
                      multilineLimit={5000}
                    />
                    {errors.description && <span className={Style.errorDescription}>{errors.description}</span>}
                  </div>
                </Grid>
              </Grid>
              <div className={isEdit ? Style.noBorder : Style.formDotContainer}>
                {!isEdit && previewUrl && (
                  <div className={Style.imgPreview}>
                    {selectedFile?.name}

                    <Button
                      type='icon-square-secondary'
                      size='small'
                      icon='delete'
                      cssClass={Style.deleteButton}
                      onClick={handleClearPic}
                    />
                  </div>
                )}

                {!isEdit && !previewUrl && (
                  <Container fluid cssClass={Style.paddingX}>
                    <div className={Style.imgaeUploadButtons}>
                      <button
                        className={Style.dropArea}
                        onDrop={(e) => handleFileDrop(e)}
                        onDragOver={(e) => handleDragOver(e)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            document.getElementById('fileInput')?.click()
                          }
                        }}
                        onClick={() => document.getElementById('fileInput')?.click()}
                        onTouchStart={() => document.getElementById('fileInput')?.click()}
                      >
                        <FileIcon height='50' width='100' />
                        <p>
                          Drop files to upload or <span className={Style.browse}>Browse</span>
                        </p>
                        <div className={Style.supportedList}>
                          <InfoTooltip show={uploadForecastFileTypes.join(', ')} colour='#1E7b80' />{' '}
                          <TextTooltip show={uploadForecastFileTypes.join(', ')}>
                            List of supported file types
                          </TextTooltip>
                        </div>
                        <input
                          id='fileInput'
                          type='file'
                          className={Style.fileInput}
                          accept={uploadForecastFileTypes.join(',')}
                          onChange={(e) => handleForecastImageUpload(e.target.files)}
                        />
                      </button>
                    </div>
                  </Container>
                )}
              </div>
              {errors.file && <span className={Style.error}>{errors.file}</span>}

              <div className={Style.formGroup}>
                <Grid row>
                  <Grid item lg={3} xs={12}>
                    <Dropdown
                      label='Does this forecast include a Leap Year?'
                      items={[
                        { id: 'yes', label: 'Yes' },
                        { id: 'no', label: 'No' },
                      ]}
                      selectedItem={formData.is_leap_year_included ? 'yes' : 'no'}
                      onSelectItem={(id) => handleForecastLeapYearChange(id.toString())}
                      optionsHeight={200}
                      required={true}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    {errors.is_leap_year_included && <p className={Style.error}>{errors.is_leap_year_included}</p>}
                  </Grid>
                </Grid>
              </div>

              {isEdit && tableData && <TablePreview data={tableData} />}

              <div className={Style.formActions}>
                {isEdit && <Button type='secondary' size='large' label='Cancel' onClick={handleGoBackClick} />}
                <Button
                  type='custom'
                  size='medium'
                  cssClass={Style.downloadTemplate}
                  label={'Download Template File'}
                  onClick={handleDownloadTemplate}
                />
                {SecretState && !isRoleEmpty(SecretState.role) && (
                  <Button
                    type='custom'
                    size='medium'
                    cssClass={Style.uploadForecast}
                    label={isEdit ? 'Save Changes' : 'Upload File'}
                    onClick={handleForecastSubmit}
                  />
                )}
              </div>
            </div>
          </Container>
        </>
      )}
    </div>
  )
}

export default UploadForecast
