import { IBodyRequestModel } from 'src/models/api/IBodyRequestModel'
import { getToken } from './TokenManager'
import { getAsync, getAsyncList } from 'src/helpers/apiRequest'
import { DashboardRequestModal } from 'src/models/api/DashboardRequestModel'
import {
  ChartResponse,
  DashboardResponseModel,
  GetAllExecutionResponse,
  NPVbreakdownResponse,
  OverallSummary,
  ScenarioListResponseModel,
} from 'src/models/api/DashboardResponseModel'

const getdashboardkpi = async (project_id: number) => {
  try {
    const token = await getToken()
    const requestbody: IBodyRequestModel = {
      token: token,
    }
    const baseUri = `/v1/dashboard/kpi`
    const response = await getAsync<DashboardRequestModal, DashboardResponseModel>(
      `${baseUri}/${project_id}`,
      requestbody,
    )
    return response
  } catch (error) {
    throw new Error(`Error getting key performance indicator: ${error}`)
  }
}

const getAllscenarioName = async (project_id: number, scenerioName: string) => {
  try {
    const token = await getToken()
    const requestbody: IBodyRequestModel = {
      token: token,
    }
    const baseUri = `/v1/execution/scenarios/latest?project_id=${project_id}&status_id=3&name=${scenerioName}&sort_column=created_at&sort_order=desc`
    const response = await getAsync<DashboardRequestModal, ScenarioListResponseModel>(`${baseUri}`, requestbody)
    return response
  } catch (error) {
    throw new Error(`Error getting key performance indicator: ${error}`)
  }
}

const getOverallSummary = async (
  project_id: number,
  page: number,
  pageSize: number,
  sortColumn: string | number,
  sortOrder: string,
  searchquery: string,
) => {
  try {
    const token = await getToken()
    const requestbody: IBodyRequestModel = {
      token: token,
    }
    const baseUri = `/v1/dashboard/overall-summary/${project_id}?status_id=${4}&name=${searchquery}&sort_column=${sortColumn}&sort_order=${sortOrder}&page=${page}&size=${pageSize}`
    const response = await getAsync<DashboardRequestModal, OverallSummary>(`${baseUri}`, requestbody)
    return response
  } catch (error) {
    throw new Error(`Error getting key performance indicator: ${error}`)
  }
}

const getnpv = async (
  project_id: number,
  page: number,
  pageSize: number,
  sortColumn: string | number,
  sortOrder: string,
  searchquery: string,
) => {
  try {
    const token = await getToken()
    const requestbody: IBodyRequestModel = {
      token: token,
    }
    const baseUri = `/v1/dashboard/npv/${project_id}?status_id=${4}&name=${searchquery}&sort_column=${sortColumn}&sort_order=${sortOrder}&page=${page}&size=${pageSize}`
    const response = await getAsync<DashboardRequestModal, NPVbreakdownResponse>(`${baseUri}`, requestbody)
    return response
  } catch (error) {
    throw new Error(`Error getting key performance indicator: ${error}`)
  }
}

const getallExecutionData = async (project_id: number) => {
  try {
    const token = await getToken()
    const requestbody: IBodyRequestModel = {
      token: token,
    }
    const baseUri = `/v1/execution/scenarios/latest?project_id=${project_id}&status_id=4&sort_column=updated_at&sort_order=desc`
    const response = await getAsyncList<DashboardRequestModal, GetAllExecutionResponse>(`${baseUri}`, requestbody)
    return response
  } catch (error) {
    throw new Error(`Error getting key performance indicator: ${error}`)
  }
}

const getEnergy = async (execution_scenario_association_id: number) => {
  try {
    const token = await getToken()
    const requestbody: IBodyRequestModel = {
      token: token,
    }
    const baseUri = `/v1/dashboard/energy/${execution_scenario_association_id}`
    const response = await getAsyncList<DashboardRequestModal, ChartResponse>(`${baseUri}`, requestbody)
    return response
  } catch (error) {
    throw new Error(`Error getting key performance indicator: ${error}`)
  }
}

export { getdashboardkpi, getAllscenarioName, getOverallSummary, getnpv, getallExecutionData, getEnergy }
