import { Button, Dropdown, IDropdownItemProps, Icon } from '@aurecon-creative-technologies/styleguide'
import { FC, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { inProductedSecretState, navlinksSelector, selectedProjectIdState } from 'src/stores/AppStore'
import { ReactComponent as ESROLogo } from '../assets/logo-esro.svg'
import Styles from '../styles/Dropdown.module.scss'
import Style from '../styles/Navbar.module.sass'
import { isRoleEmpty } from 'src/helpers/appRoles'

interface INavbarProps {
  placeholder: string
  dropdownItems: IDropdownItemProps[]
  selectedItem: number
  onSelectItem: (item: string | number) => void
}

const Navbar: FC<INavbarProps> = ({ placeholder, dropdownItems, selectedItem, onSelectItem }) => {
  const navigate = useNavigate()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const location = useLocation()
  const navlinks = useRecoilValue(navlinksSelector)
  const projectId = useRecoilValue(selectedProjectIdState)
  const SecretState = useRecoilValue(inProductedSecretState)
  const handleButtonClick = (href: string) => {
    setIsMenuOpen(false)
    navigate(href)
  }
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleCloseMenu = () => {
    setIsMenuOpen(false)
  }

  const handleOpenHomeClick = () => {
    navigate('/')
  }

  const handleUserSettings = () => {
    navigate(`/settings`)
  }
  const handleLogout = () => {
    navigate(`/profile`)
  }

  const renderDropdownItem = (item: IDropdownItemProps) => (
    <div className={Style.dropdownItem}>
      <Icon type='folder' size='16px' className={Style.folderIcon} outlined />
      <span>{item.label}</span>
    </div>
  )

  return (
    <div className={Style.navbar}>
      <div className={Style.logo}>
        <ESROLogo height={60} width={60} onClick={handleOpenHomeClick} />

        {location.pathname !== '/' &&
          location.pathname !== '/profile' &&
          location.pathname !== '/createproject' &&
          location.pathname !== '/settings' &&
          !location.pathname.startsWith('/editproject/') && (
            <div>
              <Button type='custom' size='large' icon='menu' cssClass={Style.menuToggle} onClick={toggleMenu} />
              <div className={`${Style.menubuttons} menubuttons`}>
                {selectedItem !== 0 &&
                  navlinks.map(({ label, href }) => (
                    <Button
                      key={label}
                      type='secondary'
                      cssClass={`transparent-button ${location.pathname === href ? 'is-active' : ''}`}
                      label={label}
                      href={href}
                      onClick={() => handleButtonClick(href)}
                    />
                  ))}
              </div>
            </div>
          )}
      </div>

      {/* Sliding Menu */}
      <div className={`${Style.sideMenu} ${isMenuOpen ? Style.sideMenuOpen : ''}`}>
        <Button type='custom' size='medium' icon='close' onClick={handleCloseMenu} cssClass={Style.closeButton} />
        {navlinks.map(({ label, href }) => (
          <Button
            key={label}
            type='secondary'
            cssClass={`${Style.sideMenuButton} transparent-button ${location.pathname === href ? 'is-active' : ''}`}
            label={label}
            href={href}
            onClick={() => handleButtonClick(href)}
          />
        ))}
      </div>

      <div className={Style.navright}>
        {location.pathname !== '/' &&
          location.pathname !== '/projects' &&
          location.pathname !== '/createproject' &&
          location.pathname !== '/profile' &&
          location.pathname !== '/settings' &&
          !location.pathname.startsWith('/editproject/') && (
            <div>
              {!location.pathname.startsWith(`/dashboard/${projectId}/forecastingedit/`) &&
                location.pathname !== `/dashboard/${projectId}/createscenario` &&
                !location.pathname.startsWith(`/dashboard/${projectId}/editscenario/`) &&
                selectedItem !== 0 && (
                  <Dropdown
                    placeholder={placeholder}
                    items={dropdownItems}
                    selectedItem={selectedItem}
                    onSelectItem={onSelectItem}
                    selectedItemRender={renderDropdownItem}
                    optionsHeight={200}
                    cssClass={Styles.projectDropdown}
                  />
                )}
            </div>
          )}
        {SecretState && !isRoleEmpty(SecretState.role) && (
          <Icon type='settings' cssClass={Style.settingsIcon} onClick={handleUserSettings} />
        )}
        <Icon type='account_circle' cssClass={Style.settingsIcon} onClick={handleLogout} />
      </div>
    </div>
  )
}

export default Navbar
