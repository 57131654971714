import {
  Button,
  Checkbox,
  Container,
  FormInput,
  Grid,
  InfoTooltip,
  Modal,
  Radio,
  Text,
  TextTooltip,
  useModal,
  useToast,
} from '@aurecon-creative-technologies/styleguide'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue } from 'recoil'
import { fetchProjectDetails, postMultiFormData, putMultiFormData } from 'src/api/ProjectService'
import LoadingScreen from 'src/components/LoadingScreen'
import { constant } from 'src/config/constant'
import { ERROR_MESSAGES } from 'src/enums/validationConstants'
import { base64ToBlob, createFileFromBlob } from 'src/helpers/downloadFile'
import { CreateProjectRequestModel } from 'src/models/api/CreateProjectRequestModel'
import {
  inProductedSecretState,
  navbarProjectDataSelector,
  prjectFormState,
  projectDataSelector,
} from 'src/stores/AppStore'
import * as Yup from 'yup'
import { ReactComponent as FileIcon } from '../assets/fileicon.svg'
import Style from '../styles/EditProject.module.sass'
import DeleteProject from './DeleteProject'
import { isRoleEmpty } from 'src/helpers/appRoles'
import { ProjectType } from 'src/enums/ProjectEnum'
import ConfirmationModal from 'src/components/ConfirmationModal'

interface Props {
  isEdit: boolean
}

const uploadFileTypes = ['.png', '.jpg', '.jpeg']

const CreateEditProject: React.FC<Props> = ({ isEdit }) => {
  const [projectId, setProjectId] = useState('')
  const navigate = useNavigate()
  const showToast = useToast()
  const SecretState = useRecoilValue(inProductedSecretState)
  const successToast = `Project ${isEdit ? 'updated' : 'created'} successfully`
  const errorToast = `Failed to ${isEdit ? 'update' : 'create'} project`
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [formData, setFormData] = useRecoilState(prjectFormState)
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)
  const { isShowing, toggleModal } = useModal(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingText, setLoadingText] = useState('')
  const [changeValue, setChangeValue] = useState<{ id: string; keyname: string }>({ id: '', keyname: '' })

  const { projectId: initialProjectId } = useParams<{ projectId?: string }>()
  const refreshFetchItems = useRecoilRefresher_UNSTABLE(projectDataSelector)
  const refreshFetchAllItems = useRecoilRefresher_UNSTABLE(navbarProjectDataSelector)

  useEffect(() => {
    if (isEdit && initialProjectId) {
      setProjectId(initialProjectId)
    } else {
      setProjectId('')
      setFormData({
        projectName: '',
        projectNumber: '',
        clientName: '',
        projectDesc: '',
        project_type: '1',
        is_confidential: false,
      })
    }
  }, [isEdit, setFormData, initialProjectId])

  useEffect(() => {
    const fetchDataAndUpdateFormData = async () => {
      setLoading(true)
      setLoadingText('Fetching data')
      try {
        const response = await fetchProjectDetails(projectId)
        const projectdata: CreateProjectRequestModel = {
          projectName: response['name'] || '',
          projectNumber: response['project_number'].toString() || '',
          clientName: response['client']['client_name'] || '',
          project_type: response['project_type'].toString() ?? '1',
          projectDesc: response['description'] || '',
          is_confidential: response['is_confidential'] || false,
        }

        setFormData(projectdata)

        if (response['image']['image_data']) {
          const mimeType = 'image/jpeg'
          const base64String = response['image']['image_data']
          const blob = base64ToBlob(base64String, mimeType)
          const file = createFileFromBlob(blob, response['image']['file_name'])
          setSelectedFile(file)
          setPreviewUrl(URL.createObjectURL(file))
        }
      } catch (error) {
        console.error('Error fetching project data:', error)
      } finally {
        setLoading(false)
      }
    }

    if (isEdit && projectId) {
      fetchDataAndUpdateFormData()
    }
  }, [isEdit, projectId, setFormData])

  const [errors, setErrors] = useState({
    projectName: '',
    projectNumber: '',
    clientName: '',
    projectDescription: '',
    project_type: '',
    is_confidential: '',
    filesize: '',
  })

  const validationSchema = Yup.object().shape({
    projectName: Yup.string().required(ERROR_MESSAGES.requiredField),
    projectNumber: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? undefined : value))
      .required(ERROR_MESSAGES.requiredField)
      .min(1, ERROR_MESSAGES.numberMin)
      .max(9999999, ERROR_MESSAGES.numberMax6Digits)
      .integer(ERROR_MESSAGES.numberInteger)
      .positive(ERROR_MESSAGES.numberPositive),
    clientName: Yup.string().required(ERROR_MESSAGES.requiredField),
    is_confidential: Yup.boolean().required(ERROR_MESSAGES.requiredField),
    project_type: Yup.string().required(ERROR_MESSAGES.requiredField),
    projectDescription: Yup.string().max(5000, ERROR_MESSAGES.descriptionMax).required(ERROR_MESSAGES.requiredField),
    filesize: Yup.mixed<File>()
      .nullable()
      .test('fileType', `Unsupported file type. Supported types: ${uploadFileTypes.join(', ')}`, (value) => {
        if (!value) return true
        const file = value as File
        const fileType = `.${file.name.split('.').pop()?.toLowerCase()}`
        return uploadFileTypes.includes(fileType)
      })
      .test('fileSize', ERROR_MESSAGES.fileSize, (value) => {
        if (!value) return true
        const file = value as File
        const maxSizeInMB = constant.UPLOAD_IMAGE_SIZE_LIMIT
        return file && file.size / 1024 / 1024 <= maxSizeInMB
      }),
  })

  const handleProjectImageUpload = (files: FileList | null) => {
    if (files) {
      const fileArray = Array.from(files)
      setSelectedFile(fileArray.length > 0 ? fileArray[0] : null)
    } else {
      setSelectedFile(null)
    }
  }

  const handleProjectFileDrop = (e: React.DragEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    if (file) {
      setSelectedFile(file)
    }
  }

  const handleProjectDragOver = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
  }

  const handleDeleteProjectClick = () => {
    toggleModal()
    setShowConfirmation(true)
    setShowConfirmationModal(false)
  }

  const handleCancelClick = () => {
    navigate('/projects')
  }

  const handleClearPic = () => {
    setSelectedFile(null)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    let newErrors = {
      projectName: '',
      projectNumber: '',
      clientName: '',
      projectDescription: '',
      project_type: '',
      is_confidential: '',
      filesize: '',
    }

    try {
      await validationSchema.validate(
        {
          projectName: formData.projectName,
          projectNumber: formData.projectNumber,
          clientName: formData.clientName,
          projectDescription: formData.projectDesc,
          project_type: formData.project_type,
          is_confidential: formData.is_confidential,
          filesize: selectedFile,
        },
        { abortEarly: false },
      )

      setErrors({
        projectName: '',
        projectNumber: '',
        clientName: '',
        projectDescription: '',
        project_type: '',
        is_confidential: '',
        filesize: '',
      })
      setLoading(true)
      setLoadingText('Submitting data')
      try {
        const response = isEdit
          ? await putMultiFormData(projectId, formData, selectedFile)
          : await postMultiFormData(formData, selectedFile)

        if (response.success) {
          refreshFetchAllItems()
          handleToast(successToast, 'success', 3000)
          handleCancelClick()
        } else {
          const errorMessage = response.message ?? 'An error occurred while submitting the form.'
          handleToast(errorMessage, 'error', 3000)
        }
      } catch (error) {
        handleToast(errorToast, 'error', 3000)
      } finally {
        refreshFetchItems()
        setLoading(false)
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((err) => {
          if (err.path) {
            newErrors = { ...newErrors, [err.path]: err.message }
          }
        })
        setErrors(newErrors)
      }
    }
  }

  const handleToast = (message: string, toastType: 'success' | 'error' | 'info' | 'warning', timeout: number) => {
    showToast.addToast({
      message: message,
      type: toastType,
      timeout: timeout,
    })
  }

  const handleChange = (fieldName: string) => (value: string | boolean) => {
    if (fieldName === 'projectNumber' && typeof value === 'string' && value.length > 6) {
      value = value.slice(0, 6)
    }
    setFormData((prev) => ({ ...prev, [fieldName]: value }))
  }

  const handleProjectTypeAction = (id: string, keyname: string) => {
    setChangeValue({ id, keyname })
    toggleModal()
    setShowConfirmation(false)
    setShowConfirmationModal(true)
  }

  const handleProjectType = (id: string, keyname: string) => {
    setFormData((prev) => ({ ...prev, [keyname]: id }))
    setErrors((prev) => ({ ...prev, [keyname]: '' }))
    if (showConfirmationModal) {
      toggleModal()
      setShowConfirmationModal(false)
    }
  }

  useEffect(() => {
    if (selectedFile) {
      setPreviewUrl(URL.createObjectURL(selectedFile))
    } else {
      setPreviewUrl(null)
    }
  }, [selectedFile])

  const getAction = () => {
    if (SecretState !== null && isRoleEmpty(SecretState.role) && isEdit) {
      return 'View'
    }
    if (isEdit) {
      return 'Edit'
    }
    return 'Create'
  }

  const isDisabled = SecretState !== null && isRoleEmpty(SecretState.role)
  const action = getAction()

  return (
    <Container fluid>
      {loading ? (
        <LoadingScreen text={loadingText} />
      ) : (
        <div>
          <div className={Style.mainBlock}>
            <h2 className={Style.titleHead}>{action}</h2>
          </div>
          <div className={Style.marginBtm}>
            <Container fluid cssClass={Style.paddingX}>
              <div className={Style.projectForm}>
                <div className={Style.textContainer}>
                  <div className={Style.formGroup}>
                    <label>
                      <FormInput
                        type='text'
                        key='projectName'
                        label='Project Name'
                        value={formData.projectName}
                        onChange={handleChange('projectName')}
                        required
                        disabled={isDisabled}
                      />
                      {errors.projectName && <span className={Style.error}>{errors.projectName}</span>}
                    </label>
                  </div>
                  <div className={classNames(Style.formGroup)}>
                    <label>
                      <FormInput
                        type='text'
                        key='projectNumber'
                        label='Project Number'
                        value={formData.projectNumber}
                        onChange={handleChange('projectNumber')}
                        required
                        disabled={isDisabled}
                      />
                      {errors.projectNumber && <span className={Style.error}>{errors.projectNumber}</span>}
                    </label>
                  </div>
                </div>

                <div className={Style.formGroup}>
                  <label>
                    <FormInput
                      type='text'
                      key='clientName'
                      label='Client'
                      value={formData.clientName}
                      onChange={handleChange('clientName')}
                      required
                      disabled={isDisabled}
                    />
                    {errors.clientName && <span className={Style.error}>{errors.clientName}</span>}
                  </label>
                </div>
                <div className={Style.formGroup}>
                  <Radio
                    items={ProjectType}
                    selectedItem={formData.project_type}
                    onItemSelect={(id) => {
                      if (isEdit) handleProjectTypeAction(id, 'project_type')
                      if (!isEdit) handleProjectType(id, 'project_type')
                    }}
                    label=''
                    horizontal
                  />
                </div>
                <Grid item lg={12}>
                  <Checkbox
                    label='Confidential Project'
                    checked={formData.is_confidential || false}
                    onChange={handleChange('is_confidential')}
                    disabled={isDisabled}
                  />
                  {errors.is_confidential && <span className={Style.error}>{errors.is_confidential}</span>}
                </Grid>

                <div className={Style.formGroup}>
                  <FormInput
                    type='multiline'
                    key='projectDesc'
                    label='Description'
                    value={formData.projectDesc}
                    onChange={handleChange('projectDesc')}
                    required
                    multilineLimit={5000}
                    disabled={isDisabled}
                  />
                  {errors.projectDescription && (
                    <span className={Style.errorDescription}>{errors.projectDescription}</span>
                  )}
                </div>
                <Text type='h5' cssClass={Style.textFeature}>
                  Feature Image
                </Text>
                <div className={previewUrl ? Style.noBorder : Style.formDotContainer}>
                  {previewUrl && (
                    <div className={Style.imgPreview}>
                      <img src={previewUrl} alt='Preview' />
                    </div>
                  )}

                  {!previewUrl && (
                    <Container fluid cssClass={Style.paddingX}>
                      <div className={Style.imgaeUploadButtons}>
                        <button
                          className={Style.dropArea}
                          onDrop={(e) => handleProjectFileDrop(e)}
                          onDragOver={(e) => handleProjectDragOver(e)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              document.getElementById('fileInput')?.click()
                            }
                          }}
                          onClick={() => document.getElementById('fileInput')?.click()}
                          onTouchStart={() => document.getElementById('fileInput')?.click()}
                        >
                          <FileIcon height='50' width='100' />
                          <p>
                            Drop files to upload or <span className={Style.browse}>Browse</span>
                          </p>
                          <h5 className={Style.maxFileSize}>(Max file size: {constant.UPLOAD_IMAGE_SIZE_LIMIT} MB)</h5>
                          <div className={Style.supportedList}>
                            <InfoTooltip show={uploadFileTypes.join(', ')} colour='#1E7b80' />{' '}
                            <TextTooltip show={uploadFileTypes.join(', ')}>List of supported file types</TextTooltip>
                          </div>
                          <input
                            id='fileInput'
                            type='file'
                            className={Style.fileInput}
                            accept={uploadFileTypes.join(',')}
                            onChange={(e) => handleProjectImageUpload(e.target.files)}
                          />
                        </button>
                      </div>
                    </Container>
                  )}
                  {previewUrl && (
                    <Grid row gap={20}>
                      <Grid item right cell>
                        {SecretState && !isRoleEmpty(SecretState.role) && isEdit && (
                          <Button
                            type='icon-square-secondary'
                            size='small'
                            icon='delete'
                            cssClass={Style.deleteButton}
                            onClick={handleClearPic}
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}
                </div>
                {errors.filesize && <span className={Style.errorFileUpload}>{errors.filesize}</span>}
                <div className={Style.formActions}>
                  {SecretState && !isRoleEmpty(SecretState.role) && isEdit && (
                    <Button
                      type='custom'
                      size='medium'
                      cssClass={`is-white ${Style.delButton}`}
                      label='Delete'
                      onClick={handleDeleteProjectClick}
                    />
                  )}
                  <Button
                    type='custom'
                    size='medium'
                    label='Cancel'
                    onClick={handleCancelClick}
                    cssClass={Style.cancelProject}
                  />
                  {SecretState && !isRoleEmpty(SecretState.role) && (
                    <Button
                      type='custom'
                      size='medium'
                      label={isEdit ? 'Save' : 'Create'}
                      onClick={handleSubmit}
                      cssClass={Style.saveProject}
                    />
                  )}

                  <Modal
                    isShowing={isShowing}
                    onClose={toggleModal}
                    size='medium'
                    cssClass='modalBackgroundWhite'
                    isCloseButton={true}
                    isOverlay={true}
                    shouldOverlayClose={true}
                  >
                    <div>
                      {!showConfirmation && (
                        <ConfirmationModal
                          title='Confirm'
                          message='Are you sure you wish to proceed?'
                          onCancel={() => {
                            toggleModal()
                            setShowConfirmationModal(!showConfirmationModal)
                          }}
                          yesButton='Yes'
                          noButton='No'
                          onConfirm={() => handleProjectType(changeValue.id, changeValue.keyname)}
                        />
                      )}
                      {!showConfirmationModal && <DeleteProject projectId={projectId} onClose={handleCancelClick} />}
                    </div>
                  </Modal>
                </div>
              </div>
            </Container>
          </div>
        </div>
      )}
    </Container>
  )
}

export default CreateEditProject
